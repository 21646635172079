<template>
  <div>
    <b-card class="mb-2">
      <div slot="header">
        <strong>주문 검색</strong>
      </div>
      <b-row class="mb-2">
        <b-col md="8">
          <b-input-group>
            <b-input-group-prepend>
              <b-button variant="primary" @click="list()" :disabled="busy.list">
                <i class="fa fa-search"></i> 검색
                <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
              </b-button>
            </b-input-group-prepend>
            <b-form-input id="search" type="text" placeholder="고객 QR, 주문 QR, 주문번호, 이름, 전화번호, 운송장, 발란코드, Log, Memo 등을 넣어주세요" v-model="form.search" @keypress.enter="list()" autocomplete="off" v-focus></b-form-input>
<!--            <b-input-group-append>
              <b-button variant="light" v-b-toggle.collapse1>검색조건 <i class="fa fa-chevron-down"></i></b-button>
            </b-input-group-append>-->
          </b-input-group>
        </b-col>
        <b-col md="4">
          <b-input-group>
            <b-input-group-prepend>
              <b-button variant="primary" @click="list()" :disabled="busy.list">
                <i class="fa fa-search"></i> 전화번호 검색
                <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
              </b-button>
            </b-input-group-prepend>
            <b-form-input id="search" type="text" placeholder="ex: 01012345678 or 5678" v-model="form.phoneSearch" @keypress.enter="list()" autocomplete="off"></b-form-input>
            <b-input-group-append>
<!--              <b-button variant="light" @click="modal.phoneSearchHelp = true"><i class="fa fa-question"></i></b-button>-->
              <b-button variant="light" @click="$utils.open('https://soapy-painter-778.notion.site/b79b0bae5d514ecfbd7fc6372565a17e')"><i class="fa fa-question"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-collapse visible id="collapse1">
        <b-row class="mb-2">
          <b-col cols="7">
            <small>주문일</small><br/>
            <date-from-to :from.sync="form.orderDateFrom" :to.sync="form.orderDateTo" v-bind="{init: '1 month', all: true, absMonth: 12, year: 5}" @enter="list()">
            </date-from-to>
          </b-col>
          <b-col cols="5">
            <small>컬럼검색</small><br/>
            <b-input-group>
              <b-input-group-prepend>
                <b-form-select v-model="form.targetCol" :options="cols"></b-form-select>
              </b-input-group-prepend>
              <b-input v-model="form.targetSearch" @keypress.enter="list()"></b-input>
            </b-input-group>
          </b-col>
        </b-row>
        <shop-preset class="mb-3" v-model="form.shop"></shop-preset>

        <b-collapse id="collapse2">
          <b-row class="mb-2">
            <b-col lg="6">
              <brand-preset v-if="$R('BALAANEER')" v-model="form.brand" :hideDisabled="true"></brand-preset>
            </b-col>
            <b-col lg="6">
              <category-preset v-if="$R('BALAANEER')" v-model="form.category"></category-preset>
            </b-col>
          </b-row>

          <b-tabs class="mb-2" v-model="form.statusIndex">
            <b-tab title="주문상태 및 일자 지정">
              <b-button class="mr-1 mb-1" size="sm" variant="warning" @click="setStatus('ALL', '')">초기화</b-button>
              <b-button class="mr-1 mb-1" size="sm" variant="success" @click="setStatus('del_prepare', 2, 2)">배송준비+2</b-button>
              <b-button class="mr-1 mb-1" size="sm" variant="success" @click="setStatus('del_prepare', 3, 3)">배송준비+3</b-button>
              <b-button class="mr-1 mb-1" size="sm" variant="success" @click="setStatus('del_prepare', 4, 4)">배송준비+4</b-button>
              <b-button class="mr-1 mb-1" size="sm" variant="success" @click="setStatus('del_prepare', 5, 5)">배송준비+5</b-button>
              <b-button class="mr-1 mb-1" size="sm" variant="success" @click="setStatus('del_prepare', 6, 6)">배송준비+6</b-button>
              <b-button class="mr-1 mb-1" size="sm" variant="success" @click="setStatus('del_prepare', 7, 7)">배송준비+7</b-button>
              <!--<b-button class="mr-1 mb-1" size="sm" variant="secondary" @click="setStatus('del_prepare', 3, 7)">배송준비 3~7</b-button>
              <b-button class="mr-1 mb-1" size="sm" variant="secondary" @click="setStatus('del_prepare', 7, '')">배송준비 7~</b-button>
              <b-button class="mr-1 mb-1" size="sm" variant="info" @click="setStatus('del_abroad', 2, 2)">통관지연+2</b-button>
              <b-button class="mr-1 mb-1" size="sm" variant="secondary" @click="setStatus('del_abroad', 3, 5)">통관지연 3~5</b-button>
              <b-button class="mr-1 mb-1" size="sm" variant="secondary" @click="setStatus('del_abroad', 5, '')">통관지연 5~</b-button>-->
              <b-form-radio-group id="status" class="mt-1" v-model="form.status" :options="[{text:'전체상태', value:'ALL'}].concat($C.ORDER_STATUS_CODE.map(e=>({text:e.name, value:e.code})))"></b-form-radio-group>
              <b-form class="mt-1" inline>
                <b-form-radio v-model="form.statusDateType" name="customRadio" value="day">지정일자 : </b-form-radio> &nbsp;
                <date-input v-model="form.statusDate" @change="v=>{form.statusDate=v}" @enter="list()"></date-input>
                <b-form-radio v-model="form.statusDateType" class="ml-3" name="customRadio" value="days">지연일수 : </b-form-radio> &nbsp;
                D+<b-input class="w-day" placeholder="부터" v-model.number="form.statusFrom" @keypress.enter="list()" @focus="form.statusDateType = 'days'"></b-input>
                &nbsp;~
                D+<b-input class="w-day" placeholder="까지" v-model.number="form.statusTo" @keypress.enter="list()" @focus="form.statusDateType = 'days'"></b-input>
              </b-form>
            </b-tab>
            <b-tab title="여러 주문상태 지정">
              <b-button class="mr-1 mb-1" size="sm" variant="primary" @click="toggleStatus">전체 선택</b-button>
              <b-button class="mr-1 mb-1" size="sm" variant="success" @click="setStatusMulti('del_abroad,del_domestic')">배송중</b-button>
              <b-button class="mr-1 mb-1" size="sm" variant="warning" @click="setStatusMulti('withdraw,cancel_complete')">품절</b-button>
              <b-form-checkbox-group name="statusMulti" v-model="form.statusMulti">
                <b-form-checkbox v-for="s in $C.ORDER_STATUS_CODE" :key="s.code" :value="s.code">{{s.name}}</b-form-checkbox>
              </b-form-checkbox-group>
            </b-tab>
          </b-tabs>

          <b-button class="mr-1 mb-1" size="sm" variant="primary" @click="toggleMall">전체 선택</b-button>
          <b-form-checkbox-group id="mall" name="selectedMall" v-model="form.mall">
            <b-form-checkbox v-for="s in this.$C.MALL.filter(e => e.use)" :key="s.name" :value="s.name">{{s.name}}</b-form-checkbox>
          </b-form-checkbox-group>
          <div class="mb-2"></div>

          <b-row>
            <b-col cols="6">
              <b-row>
                <b-col>
                  <div class="label-sm">가격조건</div>
                  <b-input-group>
                    <b-form-input type="number" placeholder="최소가" v-model.number="form.min" @keypress.enter="list()"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="primary"><i class="fa fa-exchange"></i></b-button>
                    </b-input-group-append>
                    <b-form-input type="number" placeholder="최대가" v-model.number="form.max" @keypress.enter="list()"></b-form-input>
                  </b-input-group>

<!--                  <div class="label-sm">EC 로 다운받은 주문만</div>
                  <b-form-checkbox class="col-form-label" v-model="form.has_ec"></b-form-checkbox>-->
                  <b-row>
                    <b-col cols="6">
                      <div class="label-sm">발란몰 m_no</div>
                      <b-form-input type="text" v-model.number="form.m_no" placeholder="" @keypress.enter="list()"></b-form-input>
                    </b-col>
                    <b-col cols="6">
                      <div class="label-sm">발란몰 email</div>
                      <b-form-input type="text" v-model="form.email" placeholder="" @keypress.enter="list()"></b-form-input>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="6">
                      <div><small>1차 배송사(물류)</small></div>
                      <b-input-group>
                        <b-input-group-prepend>
                          <delivery-dropdown variant="light" @setCompany="setFormAbroadComp"></delivery-dropdown>
                        </b-input-group-prepend>
                        <b-form-input type="text" v-model="form.abroad_del_company" placeholder="" @keypress.enter="list()"></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col cols="6">
                      <div><small>2차 배송사(고객)</small></div>
                      <b-input-group>
                        <b-input-group-prepend>
                          <delivery-dropdown variant="light" @setCompany="setFormDomesticComp"></delivery-dropdown>
                        </b-input-group-prepend>
                        <b-form-input type="text" v-model="form.domestic_del_company" placeholder="" @keypress.enter="list()"></b-form-input>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <div class="label-sm">1차 딜레이</div>
              <b-form inline>
                <b-button class="mr-2" size="sm" variant="primary" @click="toggleDelay1st">전체 선택</b-button>
                <b-form-checkbox-group v-model="form.delay_1st">
                  <b-form-checkbox class="col-form-label" :key="'NONE'" :value="'NONE'">지연없음</b-form-checkbox>
                  <b-form-checkbox class="col-form-label" v-for="e in $C.DELAY_STATUS" :key="e.code" :value="e.code">{{e.name}}({{e.code}})</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form>
              <div class="label-sm">2차 딜레이</div>
              <b-form inline>
                <b-button class="mr-2" size="sm" variant="primary" @click="toggleDelay2nd">전체 선택</b-button>
                <b-form-checkbox-group v-model="form.delay_2nd">
                  <b-form-checkbox class="col-form-label" :key="'NONE'" :value="'NONE'">지연없음</b-form-checkbox>
                  <b-form-checkbox class="col-form-label" v-for="e in $C.DELAY_STATUS" :key="e.code" :value="e.code">{{e.name}}({{e.code}})</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form>

              <b-row>
                <b-col>
                  <div class=""><small>limit</small></div>
                  <b-form-input id="limit" type="text" placeholder="한 번에 가져올 주문 수" v-model.number="form.limit" @keypress.enter="list()"></b-form-input>
                  <div><small class="text-muted">적절한 limit은 빠른 검색에 도움이 됩니다</small></div>
                </b-col>
                <b-col>
                  <div class=""><small>_diff._t</small></div>
                  <b-form-input type="text" placeholder="수정한 timestamp" v-model.number="form._t" @keypress.enter="list()"></b-form-input>
                  <div><small class="text-muted">수정이력을 기준으로 검색합니다</small></div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div class=""><small>주문번호</small></div>
                  <b-form-textarea :rows="3" v-model.trim="order_no" placeholder=""></b-form-textarea>
                  <div><small class="text-muted">엔터로 구분된 주문번호 혹은 주문상세번호(일련번호)를 입력해주세요</small></div>
                </b-col>
                <b-col>
                  <div class=""><small>발란코드</small></div>
                  <b-form-textarea id="goodsNo" :rows="3" v-model.trim="goods_no" placeholder="goods_no를 입력해주세요"></b-form-textarea>
                  <div><small class="text-muted">엔터 등으로 구분된 goods_no를 입력해주세요</small></div>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="6">
              <small>있음 / 없음 필터</small>
              <b-form-group class="mb-0" label="주문 아이템수" :label-cols="3">
                <b-form-radio-group class="col-form-label" v-model="form.multiple" :options="[
                  {text: '전체', value: 'ALL'},
                  {text: '1개', value: '1'},
                  {text: '2개', value: '2'},
                  {text: '3개', value: '3'},
                  {text: '4개 이상', value: '4+'},
                ]"></b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-0" label="교환주문" :label-cols="3">
                <b-form-radio-group class="col-form-label" v-model="form.exchange" :options="[
                  {text: '전체', value: 'ALL'},
                  {text: '교환주문만', value: 'y'},
                ]"></b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-0" label="오늘도착" :label-cols="3">
                <b-form-radio-group class="col-form-label" v-model="form.oneday_delivery" :options="$C.OPTIONS.EXISTS_Y"></b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-0" label="배송유형" :label-cols="3">
                <b-form-radio-group class="col-form-label" v-model="form.delivery_type" :options="[
                  {text: '전체', value: 'ALL'},
                  {text: '국내', value: '국내'},
                  {text: '해외', value: '해외'},
                ]"></b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-0" label="오늘출발" :label-cols="3">
                <b-form-radio-group class="col-form-label" v-model="form.today_pick" :options="$C.OPTIONS.EXISTS_Y"></b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-0" label="주문제작" :label-cols="3">
                <b-form-radio-group class="col-form-label" v-model="form.orderMade" :options="$C.OPTIONS.YESNO_Y"></b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-0" label="배송방식" :label-cols="3">
                <b-form-radio-group class="col-form-label" v-model="form.deliveryExtra" :options="$F.DELIVERY_EXTRA_FILTER"></b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-0" label="직배송여부" :label-cols="3">
                <b-form-radio-group class="col-form-label" v-model="form.logistics" :options="[
                  {text: '전체', value: 'ALL'},
                  {text: '직배송', value: 'direct'},
                  {text: '발란물류', value: 'balaan'},
                ]"></b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-0" label="SHOP 타입" :label-cols="3">
                <b-form-radio-group class="col-form-label" v-model="form.shop_type" :options="[
                  {text: '전체', value: 'ALL'}, ...options.shop_type
                ]"></b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-0" label="매장픽업" :label-cols="3">
                <b-form-radio-group class="col-form-label" v-model="form.store_pickup" :options="$C.OPTIONS.EXISTS_Y"></b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-0" label="선물포장" :label-cols="3">
                <b-form-radio-group class="col-form-label" v-model="form.gift_packing" :options="$C.OPTIONS.EXISTS_Y"></b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-0" label="자동주문결과" :label-cols="3">
                <b-form-radio-group class="col-form-label" v-model="form.auto_order" :options="$C.OPTIONS.EXISTS_Y"></b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-0" label="품절이력" :label-cols="3">
                <b-form-radio-group class="col-form-label" v-model="form.withdraw" :options="$C.OPTIONS.EXISTS_Y"></b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-0" label="철회이력" :label-cols="3">
                <b-form-radio-group class="col-form-label" v-model="form.withdraw_flag" :options="$C.OPTIONS.EXISTS_Y"></b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-0" label="주문변경이력" :label-cols="3">
                <b-form-radio-group class="col-form-label" v-model="form.changeOrder" :options="[
                  {text: '전체', value: 'ALL'}, ...options.changeOrder
                ]"></b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-0" label="PS Log" :label-cols="3">
                <b-form-radio-group class="col-form-label" v-model="form.ps_log" :options="$C.OPTIONS.EXISTS"></b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-0" label="운영 Log" :label-cols="3">
                <b-form-radio-group class="col-form-label" v-model="form.op_log" :options="$C.OPTIONS.EXISTS"></b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-0" label="주문관리 Log" :label-cols="3">
                <b-form-radio-group class="col-form-label" v-model="form.mng_log" :options="$C.OPTIONS.EXISTS"></b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-0" label="클레임사유" :label-cols="3">
                <b-form-radio-group class="col-form-label" v-model="form.claim_memo" :options="$C.OPTIONS.EXISTS"></b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-0" label="PS메모" :label-cols="3">
                <b-form-radio-group class="col-form-label" v-model="form.ps_memo" :options="$C.OPTIONS.EXISTS"></b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-0" label="원가테이블" :label-cols="3">
                <b-form-radio-group class="col-form-label" v-model="form.price_table" :options="$C.OPTIONS.EXISTS"></b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-0" label="마진테이블" :label-cols="3">
                <b-form-radio-group class="col-form-label" v-model="form.margin_table" :options="$C.OPTIONS.EXISTS"></b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-0" label="주문교체이력" :label-cols="3">
                <b-form-radio-group class="col-form-label" v-model="form.ordershift" :options="$C.OPTIONS.EXISTS"></b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-0" label="지연사례" :label-cols="3">
                <b-form-checkbox-group v-model="form.delay">
                  <b-form-checkbox class="col-form-label" :key="'NONE'" :value="'NONE'">지연없음</b-form-checkbox>
                  <b-form-checkbox class="col-form-label" v-for="e in $C.DELAY_CODE" :key="e.code" :value="e.code">{{e.name}}</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
              <b-form-group class="mb-0" label="연동형태" :label-cols="3">
                <b-form-radio-group class="col-form-label" v-model="form.manual" :options="[
                  {text: '전체', value: 'ALL'},
                  {text: '자동(FEED)', value: 'auto'},
                  {text: '파트너관리', value: 'manual'}
                ]"></b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-0" label="가송장 의심" :label-cols="3">
                <b-form-radio-group class="col-form-label" v-model="form.is_wrong_invoice" :options="[
                  {text: '전체', value: 'ALL'},
                  {text: 'Y', value: true},
                  {text: 'N', value: false}
                ]"></b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-0" label="Dynamic Pricing" :label-cols="3">
                <b-form-radio-group class="col-form-label" v-model="form.dynamicPricing" :options="[
                  {text: '전체', value: 'ALL'},
                  {text: '존재함', value: 'y'},
                  {text: '가격변화없음', value: 'unchanged'},
                  {text: '달성함', value: 'positive'},
                  {text: '미달성', value: 'negative'},
                  {text: '고정할인', value: 'fixed'},
                ]"></b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-0" label="구매확정여부" :label-cols="3">
                <b-form-radio-group class="col-form-label" v-model="form.orderConfirmation" :options="$C.OPTIONS.EXISTS"></b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-0" label="상품유형" :label-cols="3">
                <b-form-radio-group class="col-form-label" v-model="form.goodsType" :options="[
                  {text: '전체', value: 'ALL'},
                  {text: '새상품만', value: 'new'},
                  {text: '빈티지만', value: 'used'}
                ]"></b-form-radio-group>
              </b-form-group>
              <b-form-group class="mb-0" label="빈티지등급" :label-cols="3" v-if="form.goodsType === 'used'">
                <b-button class="mr-1" size="sm" variant="light" @click="toggleUsedGrade()">전체</b-button>
                <b-button class="mr-1" size="sm" variant="primary" @click="toggleUsedGrade('S')">S</b-button>
                <b-button class="mr-1" size="sm" variant="success" @click="toggleUsedGrade('A')">A</b-button>
                <b-button class="mr-2" size="sm" variant="warning" @click="toggleUsedGrade('B')">B</b-button>
                <b-form-checkbox-group v-model="form.usedGrade" class="d-inline">
                  <b-form-checkbox class="col-form-label" v-for="s in $C.USED_GRADE" :key="s.value" :value="s.value" :title="s.desc">{{s.text}}</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-collapse>
      </b-collapse>

      <div class="clearfix">
        <b-button class="" variant="primary" @click="list()" :disabled="busy.list">
          검색
          <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
        </b-button>
        <b-button class="ml-1" variant="outline-success" v-b-toggle.collapse2>상세검색조건</b-button>
        <b-button v-if="$R('ORDER_WB')" class="ml-1" variant="outline-info" v-b-toggle.collapse_xlsx @dragenter="collapse.xlsx = true">엑셀업로드</b-button>
<!--        <b-form-radio-group class="col-form-label pull-right" v-model="marginCalcType" :options="[
          {text: '쿠폰을 비용에서 제외', value: 'no_coupon'},
          {text: '쿠폰을 비용에 포함', value: 'coupon'}
        ]"></b-form-radio-group>-->
<!--        <b-button v-if="$R('ADMIN')" class="m-1 pull-right" variant="outline-light" @click="$modal.show({type:'json', item:lastQuery})">쿼리확인</b-button>-->
        <b-button v-if="$R('DEV')" class="ml-1 pull-right" :variant="query ? 'light' : 'outline-light'" @click="modal.query = true">Query</b-button>
        <b-button variant="outline-info" class="pull-right" @click="modal.help = true"><i class="fa fa-question"></i></b-button>
      </div>
    </b-card>

    <b-modal title="상태 일괄변경" size="lg" v-model="modal.statusAll" @ok="setOrderStatusAll">
      <b-alert show variant="info">총 {{items.list.filter(e=>e.selected).length}} 건의 주문의 상태를 변경합니다</b-alert>
      <label>주문상태</label>
      <b-form-radio-group id="orderStatusAllCode" v-model="statusAllCode" :options="$C.ORDER_STATUS_CODE.map(e=>({text:e.name, value:e.code}))"></b-form-radio-group>
      <label class="mt-3">상태일자</label>
      <date-input class="form-inline" v-model="statusAllDate" @change="v=>{statusAllDate=v}" @enter="list()"></date-input>
      <small class="text-muted">일자를 입력하지 않을 경우 기본적으로 오늘이 들어가게 되며 변경될 상태의 일자가 이미 있다면 그 일자를 유지하게 됩니다</small>
    </b-modal>

    <b-modal :title="`[${statusCodeMap[statusAllCode] && statusCodeMap[statusAllCode].name}] 상태로 변경 및 클레임사유 지정`"
             v-model="modal.claim">
      <div><small>상태 일자</small></div>
      <div><small>클레임사유</small></div>
      <b-input-group>
        <b-input-group-prepend>
          <b-dropdown variant="light">
            <b-dropdown-item v-for="n of formClaim.claim_set" @click="_=>{formClaim.claim_memo = n;$forceUpdate()}" :key="n">
              {{ n }}
            </b-dropdown-item>
          </b-dropdown>
        </b-input-group-prepend>
        <b-form-input type="text" v-model="formClaim.claim_memo" placeholder=""></b-form-input>
      </b-input-group>
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="setOrderStatusClaim()" :disabled="busy.claim">
          변경
          <b-spinner class="ml-1" small v-if="busy.claim"></b-spinner>
        </b-button>
        <b-button size="sm" variant="outline-secondary" @click="cancel()">
          취소
        </b-button>
      </template>
    </b-modal>

    <b-modal :title="okModalTitle" size="lg" v-model="modal.ok" ok-only ok-title="닫기">
      <div v-html="okModalHtml"></div>
    </b-modal>

    <b-modal title="추가 Query 지정" v-model="modal.query" ok-only>
      <h6>goods_no: 123, name: 'aaa'</h6>
      형식으로 입력해주세요. {} 를 제외한 object 형식에 문제가 없다면 쿼리에 추가됩니다.
      <b-textarea v-model="query"></b-textarea>
    </b-modal>

    <b-modal :title="`${{OP:'운영팀'}[customName]||customName} 엑셀 다운로드`" size="xl" v-model="modal.customXlsx" ok-only ok-title="닫기">
      <div><small>{{customName === 'wrongInvoice' ? '가송장 발생일 기준': '기간'}}</small></div>
      <date-from-to ref="customDateRange" :from.sync="formCustom.dateFrom" :to.sync="formCustom.dateTo" v-bind="{init: '1 month', twoDays: true, absMonth: 12, year: 5}">
      </date-from-to>
      <div v-if="customName === 'MD'">
        <prog class="mt-1" id="mdXlsx"></prog>
        <b-button class="m-1" variant="success" @click="downCustom('xlsx')" :disabled="busy['mdXlsx']">Xlsx <b-spinner v-if="busy['mdXlsx']" small></b-spinner></b-button>
<!--        <b-button class="m-1" variant="success" @click="downCustomOld('xlsx')" :disabled="busy['mdXlsx']">Xlsx(이전) <b-spinner v-if="busy['mdXlsx']" small></b-spinner></b-button>
        <b-button class="m-1" variant="success" @click="downCustomOld('txt')" :disabled="busy['mdXlsx']">Text(이전) <b-spinner v-if="busy['mdXlsx']" small></b-spinner></b-button><br/>-->
        <small class="text-muted">주문들은 취소, 반품 되었을 때 2번씩 등장합니다(주문: + 금액, 취소반품: - 금액)</small>
      </div>
      <div v-if="customName === 'base'">
        <prog class="mt-1" id="baseXlsx"></prog>
        <b-button class="m-1" variant="success" @click="downCustom('xlsx')" :disabled="busy['baseXlsx']">Xlsx <b-spinner v-if="busy['baseXlsx']" small></b-spinner></b-button>
<!--        <b-button class="m-1" variant="success" @click="downCustomOld('xlsx')" :disabled="busy['baseXlsx']">Xlsx(이전) <b-spinner v-if="busy['baseXlsx']" small></b-spinner></b-button>
        <b-button class="m-1" variant="success" @click="downCustomOld('txt')" :disabled="busy['baseXlsx']">Text(이전) <b-spinner v-if="busy['baseXlsx']" small></b-spinner></b-button><br/>-->
      </div>
      <div v-if="customName === 'OP'">
        <prog class="mt-1" id="opXlsx"></prog>
        <b-button class="m-1" variant="success" @click="downCustom('xlsx')" :disabled="busy['opXlsx']">Xlsx <b-spinner v-if="busy['opXlsx']" small></b-spinner></b-button>
<!--        <b-button class="m-1" variant="success" @click="downCustomOld('xlsx')" :disabled="busy['opXlsx']">Xlsx(이전) <b-spinner v-if="busy['opXlsx']" small></b-spinner></b-button>
        <b-button class="m-1" variant="success" @click="downCustomOld('txt')" :disabled="busy['opXlsx']">Text(이전) <b-spinner v-if="busy['opXlsx']" small></b-spinner></b-button><br/>-->
      </div>
      <div v-if="customName === 'withdraw'">
        <prog class="mt-1" id="withdrawXlsx"></prog>
        <b-button class="m-1" variant="success" @click="downCustom('xlsx')" :disabled="busy['withdrawXlsx']">Xlsx <b-spinner v-if="busy['withdrawXlsx']" small></b-spinner></b-button>
<!--        <b-button class="m-1" variant="success" @click="downCustomOld('xlsx')" :disabled="busy['withdrawXlsx']">Xlsx(이전) <b-spinner v-if="busy['withdrawXlsx']" small></b-spinner></b-button>
        <b-button class="m-1" variant="success" @click="downCustomOld('txt')" :disabled="busy['withdrawXlsx']">Text(이전) <b-spinner v-if="busy['withdrawXlsx']" small></b-spinner></b-button><br/>-->
      </div>
      <div v-if="customName === 'wrongInvoice'">
        <prog class="mt-1" id="wrongInvoiceXlsx"></prog>
        <b-button class="m-1" variant="success" @click="downCustom('xlsx')" :disabled="busy['wrongInvoiceXlsx']">Xlsx <b-spinner v-if="busy['wrongInvoiceXlsx']" small></b-spinner></b-button>
      </div>
    </b-modal>

    <b-collapse id="collapse_xlsx" v-model="collapse.xlsx">
      <b-row class="mb-3" v-if="$R('ORDER_WB')">
        <b-col>
          <div id="dropNew" class="drop" data-type="new" @drop.prevent.stop="handleFile" @dragover="handleDragover" @dragenter="handleDragover" @click="_=>{$refs.xlsxNew.value = null;$refs.xlsxNew.click()}">
            New File Drop or Click
          </div>
          <input type="file" ref="xlsxNew" data-type="new" style="display: none" @change="handleFile">
        </b-col>
        <b-col>
          <div id="dropUpdate" class="drop" data-type="update" @drop.prevent.stop="handleFile" @dragover="handleDragover" @dragenter="handleDragover" @click="_=>{$refs.xlsxUpdate.value = null;$refs.xlsxUpdate.click()}">
            Update File Drop or Click
          </div>
          <input type="file" ref="xlsxUpdate" data-type="update" style="display: none" @change="handleFile">
        </b-col>
      </b-row>
    </b-collapse>

    <div class="clearfix">
      <b-button v-if="$R('ORDER_WB')" class="m-1" variant="info" @click="setOrderStatusAllModal">상태일괄변경</b-button>
      <b-button v-if="$R('ORDER_X')" class="m-1" variant="warning" @click="sendKakaoAll">알림톡발송</b-button>
<!--      <b-button v-if="$R('REFUND_X')" class="m-1" variant="danger" @click="setWithdrawAll">환불처리</b-button>-->
      <b-button v-if="$R('ORDER_X')" class="m-1" variant="primary" @click="setDelPrepare" :disabled="busy.placeOrder">발주확인(배송준비중) 처리<b-spinner class="ml-1" small v-if="busy.placeOrder"></b-spinner></b-button>
      <div class="pull-right">
        <b-dropdown right text="Copy" variant="light" class="m-1">
          <b-dropdown-item @click="copyOrders('oid')">OID</b-dropdown-item>
          <b-dropdown-item @click="copyOrders('tsv')">TSV</b-dropdown-item>
          <b-dropdown-item @click="copyOrders('csv')">CSV</b-dropdown-item>
        </b-dropdown>
        <b-dropdown right text="XLSX" variant="success" class="m-1">
          <b-dropdown-item @click="down('xlsx')">기본</b-dropdown-item>
          <b-dropdown-item v-if="$R('ORDER_R')" @click="downWithCalc()">가격계산</b-dropdown-item>
<!--          <b-dropdown-item v-if="$R('ORDER_R')" @click="downSimple()">구매대행</b-dropdown-item>-->
<!--          <b-dropdown-item v-if="$R('ORDER_R')" @click="downWebsite()">Website</b-dropdown-item>-->
          <b-dropdown-item v-if="$R('ORDER_R')" @click="down('xlsx', 'boutique')">부티크</b-dropdown-item>
<!--          <b-dropdown-item v-if="$R('ORDER_R')" @click="downExpress()">익스프레스</b-dropdown-item>-->
<!--          <b-dropdown-item v-if="$R('ORDER_R')" @click="downOptions()">주문옵션 확인</b-dropdown-item>-->
          <b-dropdown-item v-if="$R('ORDER_R')" @click="downPG()">결제/정산 확인</b-dropdown-item>
          <b-dropdown-item v-if="$R('ORDER_R')" @click="downDP()">Dynamic Pricing</b-dropdown-item>
          <b-dropdown-item v-if="$R('ORDER_R')" @click="downGlobalMall()">글로벌몰</b-dropdown-item>
          <div role="group" aria-lableledby="header2">
            <b-dropdown-header id="header2">Custom Xlsx</b-dropdown-header>
            <b-dropdown-item @click="_=>{modal.customXlsx = true;customName = 'base';busy['baseXlsx']=false}">기본</b-dropdown-item>
<!--            <b-dropdown-item @click="_=>{modal.customXlsx = true;customName = 'MD';busy['mdXlsx']=false}">MD</b-dropdown-item>-->
<!--            <b-dropdown-item @click="_=>{modal.customXlsx = true;customName = 'OP';busy['opXlsx']=false}">운영팀</b-dropdown-item>-->
<!--            <b-dropdown-item @click="_=>{modal.customXlsx = true;customName = 'withdraw';busy['withdrawXlsx']=false}">품절률박멸</b-dropdown-item>-->
            <b-dropdown-item @click="_=>{modal.customXlsx = true;customName = 'wrongInvoice';busy['wrongInvoiceXlsx']=false}">가송장 심사</b-dropdown-item>
          </div>
        </b-dropdown>
        <b-button class="m-1" variant="success" @click="down('txt')">Text</b-button>
      </div>
    </div>

    <div class="order-table">
      <c-table ref="c-table:list" :table-data="items.list" :fields="fields" :perPage.sync="perPage" :isBusy="busy.list" :getMoreBusy="busy.listmore"
               :hasMore="hasMore.list" :caption="items.list.length + (hasMore.list ? '+' : '') + ' 개'"
               :emptyHtml="'주문이 없습니다. 주문일 등의 검색조건을 확인해주세요'"
               @btn-clicked="btnAction" @get-more="list(true)"></c-table>
    </div>

    <iframe ref="ifr" name="xlsx_frame" style="width:1px;height:1px;visibility:hidden"></iframe>
    <form :action="getHost() + '/data/down'" ref="xlsx_form" method="POST" target="xlsx_frame" style="width:1px;height:1px;visibility:hidden">
      <input ref="json_data" type="hidden" name="j" />
    </form>
    <form :action="getHost() + '/order/wrongInvoiceOrders'" ref="wrong_invoice_form" method="POST" target="xlsx_frame" style="width:1px;height:1px;visibility:hidden">
      <input ref="json_wrong_invoice" type="hidden" name="j" />
    </form>

    <order-modal ref="orderModal" v-bind="{colMap, shopMap, statusCodeMap, delayCodeMap, priceMetaMap, items, handleDragover}"></order-modal>

    <b-modal title="주문 다운로드 및 복사" size="lg" v-model="modal.download" @hide="formDownReason.resolve(false)">
      <b-alert show variant="info">총 {{items.list.filter(e=>e.selected).length}} 건의 주문을 다운로드 or 복사 합니다</b-alert>
      <div><small>사유</small></div>
      <b-form-select v-model="formDownReason.reason" :options="[
          {text: '주문 관련 업무(취소/환불, 반품 등)', value: 'order'},
          {text: '배송 관련 업무', value: 'deliver'},
          {text: '고객 문의 및 고충처리 관련 업무', value: 'customer'},
          {text: '마케팅 관련 업무(CRM, 온사이트 등)', value: 'marketing'},
          {text: '서비스 기획, 개발 관련 업무', value: 'develop'},
          {text: '정산관련 업무', value: 'settle'},
          {text: '기타', value: 'etc'}
        ]">
      </b-form-select>
      <b-form-input v-if="formDownReason.reason === 'etc'" v-model="formDownReason.reasonEtc" placeholder="기타 사유를 입력해 주세요(30자 제한)." maxlength="30"></b-form-input>
      <template v-slot:modal-footer="{}">
        <b-button variant="outline-secondary" @click="formDownReason.resolve(false)">
          Cancel
        </b-button>
        <b-button variant="success" @click="formDownReason.resolve(true)">
          Ok
        </b-button>
      </template>
    </b-modal>

    <help ref="help" :value.sync="modal.help"></help>
    <phone-search-help ref="phoneSearchHelp" :value.sync="modal.phoneSearchHelp"></phone-search-help>
  </div>
</template>
<style scoped>
.drop {
  border: 2px dashed #bbb;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 25px;
  text-align: center;
  font: 20pt bold,"Vollkorn";
  color: #bbb;
  cursor: pointer;
}
</style>
<script>
import cTable from '@/views/TableBase.vue'
import shopPreset from '@/views/ShopPreset.vue'
import brandPreset from '@/views/BrandPreset.vue'
import categoryPreset from '@/views/CategoryPreset.vue'
import dateInput from '@/views/modules/DateInput.vue'
import DeliveryDropdown from '@/views/modules/DeliveryDropdown.vue'
import orderModal from '@/views/order/OrderModal/OrderModal.vue'
import prog from '@/views/Progress.vue'
import help from '@/views/order/DeliveryBoardHelp.vue'
import phoneSearchHelp from '@/views/order/PhoneSearchHelp.vue'
import qs from 'querystring';
import {getHost} from '@/shared/api'
import {down, readXlsx} from '@/shared/impexp'
import ListDataMixin from '../modules/ListDataMixin'
import * as moment from 'moment-timezone';
import * as momentBiz from 'moment-business-days';

const onedayDeliveryLimit = '14:00:59';
const onedayDeliveryLimitNext = '13:00:59';
const onedayDeliveryStart = '14:00:00';

export default {
  name: 'DeliveryBoard',
  title: '배송보드',
  components: {cTable, shopPreset, brandPreset, categoryPreset, dateInput, orderModal, DeliveryDropdown, prog, help, phoneSearchHelp},
  mixins: [
    ListDataMixin
  ],
  data() {
    return {
      getHost,
      colMap: {},
      statusCodeMap: {},
      delayCodeMap: {},
      orderChangeCodeMap: {},
      cols: [],
      today: moment().format('YYYY-MM-DD'),
      shop: [],
      shopMap: {},
      brand: [],
      brandMap: {},
      exchangeMap: {},
      mallMap: {},
      priceMetaMap: {},
      form: {
        search: '',
        selectedDate: {
          start: moment().subtract(6, 'month'),
          end: moment()
        },
        targetCol: 'del_memo',
        targetSearch: '',
        phoneSearch: '',
        orderDateFrom: '',
        orderDateTo: '',
        mall: this.$C.MALL.filter(e => e.use).map(e=>e.name),
        shop: [],
        brand: [],
        category: [],
        statusIndex: 0,
        status: 'ALL',
        statusDateType: 'days',
        statusDate: '',
        statusFrom: '',
        statusTo: '',
        statusMulti: this.$C.ORDER_STATUS_CODE.map(e => e.code),
        abroad_del_company: '',
        domestic_del_company: '',
        has_ec: false,
        oneday_delivery: 'ALL',
        delivery_type: 'ALL',
        today_pick: 'ALL',
        orderMade: 'ALL',
        deliveryExtra: 'ALL',
        logistics: 'ALL',
        shop_type: 'ALL',
        store_pickup: 'ALL',
        gift_packing: 'ALL',
        auto_order: 'ALL',
        withdraw: 'ALL',
        withdraw_flag: 'ALL',
        changeOrder: 'ALL',
        exchange: 'ALL',
        ps_log: 'ALL',
        op_log: 'ALL',
        mng_log: 'ALL',
        claim_memo: 'ALL',
        ps_memo: 'ALL',
        price_table: 'ALL',
        margin_table: 'ALL',
        ordershift: 'ALL',
        manual: 'ALL',
        goodsType: 'ALL',
        is_wrong_invoice: 'ALL',
        dynamicPricing: 'ALL',
        orderConfirmation: 'ALL',
        usedGrade: this.$C.USED_GRADE.map(e => e.value),
        delay: ['NONE', 'RETRIEVE', 'APPROVE', 'FIRST_DELAY', 'SECOND_DELAY'],
        delay_1st: ['NONE', 'detect', 'confirm', 'agree', 'cancel'],
        delay_2nd: ['NONE', 'detect', 'confirm', 'agree', 'cancel'],
        multiple: 'ALL',
        min:'',
        max:'',
        m_no: '',
        email: '',
        limit: 200,
        skip: 0,
        _t: '',
      },
      goods_no: '',
      order_no: '',
      query: '',
      formCustom: {
        dateFrom: '',
        dateTo: '',
      },
      formClaim: {
        toCode: '',
        claim_memo: '',
        claim_set: [],
        status_date: '',
      },
      formDownReason: {
        resolve: () => {},
        reason: 'order',
        reasonEtc: '',
      },
      lastBody: {list: {}},
      items: {list: []},
      hasMore: {list: false},
      ac: {list: null}, // abortController

      fields: [],
      item_status_claim_set: [],
      months: [],
      perPage: 100,
      busy: {
        search: false,
        list: false,
        listmore: false,
        mdXlsx: false,
        baseXlsx: false,
        opXlsx: false,
        withdrawXlsx: false,
        placeOrder: false,
        wrongInvoiceXlsx: false,
        claim: false,
      },
      modal: {
        statusAll: false,
        ok: false,
        customXlsx: false,
        help: false,
        phoneSearchHelp: false,
        query: false,
        claim: false,
        download: false,
      },
      collapse: {
        xlsx: false,
      },
      deliveryDay: 6,
      okModalTitle: '',
      okModalHtml: '',
      statusAllCode: 'order_receive',
      statusAllDate: '',
      customName: '',
      lastQuery: {},
      marginCalcType: 'coupon',
      options: {
        shop_type: [
          {text: '부티크', value: 'boutique'},
          {text: '편집샵', value: 'edit_shop'},
          {text: '병행', value: 'parallel'},
          {text: '자사', value: 'balaan'},
          {text: '스토어', value: 'store'},
          {text: '기타', value: 'etc'},
        ],
        changeOrder: [
          {text: '옵션변경', value: 'OPTIONS'},
          {text: '배송지변경', value: 'ADDRESS'},
          {text: '재결제', value: 'REPAYMENT'},
        ],
      },
      deliveryExtraMap: this.$utils.arr2map(this.$C.DELIVERY_EXTRA, 'value', 'text'),
    }
  },
  async mounted() {
    let elem = document.createElement('script');
    elem.setAttribute('src', "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js");
    document.head.appendChild(elem);
  },
  async created() {
    this.$utils.getStatus(this.$options.name, this, 'perPage');

    this.cols = this.$C.DELIVER_BOARD_COLUMNS.map(e=>{
      this.colMap[e.code] = e.name;
      e.value = e.code;
      e.text = `${e.name} (${e.code})`;
      return e;
    });
    this.fields = [
      {key: 'selected'},
      {key: 'html1', label: '<span class="badge badge-primary">쇼핑몰</span><br/>주문코드<br/>일련번호', style: {width: '115px'}},
      {key: '_img60', label: '이미지', style: {width: '45px'}},
      {
        key: 'lambda1',
        label: '주문일시<br/><span class="badge badge-light">주문상태</span><br/>상태일시',
        class: 'text-center',
        style: {minWidth: '60px'},
        sortable: true
      },
      {key: 'html3', label: '주문자<br/>수령자', style: {minWidth: '50px'}},
      {key: 'html6', label: '주문자휴대폰<br/>수령자휴대폰', style: {width: '100px'}},
      {key: 'html2', label: '상품정보', style: {minWidth: '230px'}},
      {key: 'html4', label: '상품속성', style: {minWidth: '98px'}, class: 'text-center'},
      {key: 'html5', label: '주문속성', style: {minWidth: '82px'}, class: 'text-center'},
      {key: 'html7', label: '지연상태', style: {width: '75px'}, class: 'text-center'},
      {key: 'lambda2', label: 'PS메모<br/>LOGS<br/><span class="badge badge-light">클레임메모</span>', style: {minWidth: '60px', maxWidth: '150px'}},
      {key: 'lambda3', label: this.$R('GOODS_R') ? '판매기준가<br/>발란몰 판매금액<br/>실결제금액' : '판매기준가', style: {width: '100px', textAlign: 'right'}},
      {key: '_actions', label: '상세', style: {width: '55px', textAlign: 'center'}, buttons: [{label: '상세', event: 'show_modal'}]},
    ];

    this.busy.list = true;

    // let meta = await this.$api.getMeta();
    // if (!meta) return;
    // meta.shop.forEach(s => {
    //   s.value = s.shop_id;
    //   s.label = `${s.shop_id}. ${s.boutique}`;
    //   this.shopMap[s.shop_id] = s;
    // });
    // this.shop = meta.shop.filter(e => e.use_yn === 'y').sort((a, b) => a.shop_id - b.shop_id);
    // // this.shop = meta.shop.sort((a,b)=>a.shop_id-b.shop_id);
    //
    // this.brand = meta.brand.map(e => {
    //   return this.brandMap[e.brand_no] = {...e, value: e.brand_no, label: `${e.brand_nm} (${e.brand_nm_kr})`};
    // }).sort((a, b) => a.label.localeCompare(b.label));
    //
    // this.$C.ORDER_STATUS_CODE.forEach(e => this.statusCodeMap[e.code] = e);
    // // this.form.shop = this.shop.map(e=>e);
    //
    // meta.exchange.forEach(e => {
    //   this.exchangeMap[e.curr_unit] = e.exchange_ratio;
    // });
    // meta.price.forEach(e => {
    //   this.priceMetaMap[e.price_meta_name] = e.price_meta_value;
    // });
    //
    // this.$C.MALL.forEach(e => this.mallMap[e.name] = e);
    //
    // // 공휴일 설정
    // let holidays = meta.holiday.map(e => {
    //   if (e.require) return momentBiz().format('YYYY-') + e.date;
    //   return e.date;
    // });
    // // 작년, 내년도 추가한다
    // holidays = holidays.concat(meta.holiday.filter(e => e.require).map(e => momentBiz().add(1, 'year').format('YYYY-') + e.date));
    // holidays = holidays.concat(meta.holiday.filter(e => e.require).map(e => momentBiz().subtract(1, 'year').format('YYYY-') + e.date));
    // momentBiz.updateLocale('kr', {
    //   holidays: holidays,
    //   holidayFormat: 'YYYY-MM-DD'
    // });
    // window.moment = momentBiz;
    // window.utils = this.$utils;
    // this.$C.DELAY_CODE.forEach(e=>this.delayCodeMap[e.code] = e);
    // this.$C.ORDER_CHANGE_ITEM.forEach(e=>this.orderChangeCodeMap[e.code] = e);

    // let meta = await this.$api.getMeta();
    // if (!meta) return;
    await this.$api.getAllMeta();
    this.shop = this.$S.m.shop.list.slice();
    this.shopMap = this.$S.m.shop.map;
    this.brand = this.$S.m.brand.list.slice();
    this.brandMap = this.$S.m.brand.map;
    this.category = this.$S.m.category.list.slice();
    this.categoryMap = this.$S.m.category.map;
    this.priceMetaMap = this.$S.m.price.map;
    this.exchangeMap = this.$S.m.exchange.map;

    this.$C.ORDER_STATUS_CODE.forEach(e => this.statusCodeMap[e.code] = e);
    this.$C.MALL.forEach(e => this.mallMap[e.name] = e);
    this.$C.DELAY_CODE.forEach(e => this.delayCodeMap[e.code] = e);
    this.$C.ORDER_CHANGE_ITEM.forEach(e => this.orderChangeCodeMap[e.code] = e);

    const no = this.$route.params.no;
    const detailno = this.$route.params.detailno;
    if (no) {
      if (detailno) {
        this.form.search = `${no}|${detailno}`;
      } else {
        this.form.search = no;
      }
      this.form.orderDateFrom = '2015-06-01';
    }
    if (Object.keys(this.$route.query).length) {
      Object.keys(this.form).forEach(k=>{
        if (this.$route.query[k] != null) {
          if (~['statusIndex', 'statusFrom', 'statusTo'].indexOf(k)) {
            this.form[k] = +this.$route.query[k];
          } else {
            this.form[k] = this.$route.query[k];
          }
        }
      });
    }
    await this.list();
    if (detailno) {
      let item = this.items.list.filter(e=>e.order_detailno === detailno)[0];
      if (item) this.showModal({item});
    }

    // dynamic html 에서의 event 를 받아서 실행하기 위한 트릭 (bad)
    window._deliBoardShowModal = this._showModal.bind(this);

    // 운영팀 엑셀 월별선택용
    const monSt = moment().startOf('month');
    while (monSt >= moment().add(-12, 'month')) {
      this.months.push(monSt.format('YYYY-MM-DD'));
      monSt.add(-1, 'month');
    }
  },
  async beforeDestroy() {
    Object.values(this.ac).filter(e=>e).forEach(e=>e.abort());
  },
  sockets: {
    connect() { console.log('connect'); this.$socket.emit('test', 'test'); },
    disconnect() { console.log('disconnect'); },
    deliver_board_query(q) {
      this.lastQuery = q;
    },
    prog(obj) {
      switch (obj.type) {
        case 'done':
          this.busy[obj.group] = false;
          break;
      }
    },
  },
  watch: {
    perPage: function() {
      this.$utils.setStatus(this.$options.name, this, 'perPage');
    }
  },
  methods: {
    toggleMall() {
      this.form.mall = this.form.mall.length === this.$C.MALL.filter(e => e.use).length ? [] : this.$C.MALL.filter(e => e.use).map(e=>e.name);
    },
    toggleStatus() {
      this.form.statusMulti = this.form.statusMulti.length === this.$C.ORDER_STATUS_CODE.length ? [] : this.$C.ORDER_STATUS_CODE.map(e=>e.code);
    },
    setStatus(status, f, t) {
      this.form.status = status;
      this.form.statusDate = '';
      this.form.statusDateType = 'days';
      this.form.statusFrom = f;
      this.form.statusTo = t;
    },
    setStatusMulti(statusStr) {
      this.form.statusMulti = statusStr.split(',');
    },
    toggleDelay1st() {
      this.form.delay_1st = this.form.delay_1st.length === this.$C.DELAY_STATUS.length + 1 ? [] : ['NONE', ...this.$C.DELAY_STATUS.map(e=>e.code)];
    },
    toggleDelay2nd() {
      this.form.delay_2nd = this.form.delay_2nd.length === this.$C.DELAY_STATUS.length + 1 ? [] : ['NONE', ...this.$C.DELAY_STATUS.map(e=>e.code)];
    },
    async list(more) {
      let form = this.form;

      if (!more) { // 추가 데이터가 아닌 경우
        // validation
        if (form.mall.length === 0) { return alert('MALL 을 하나 이상 선택해주세요'); }
        if (form.statusDateType === 'day' && form.status === 'ALL') { return alert('상태일자를 지정할 경우 상태를 선택해주세요'); }
        if (form.statusDateType === 'day' && form.statusDate && !form.statusDate.match(/^\d{4}-\d{2}-\d{2}$/)) { return alert('상태일자를 YYYY-MM-DD 형식으로 지정해주세요'); }
      }

      let mall = form.mall.length === this.$C.MALL.filter(e => e.use).length ? [] : form.mall; // 전체 선택일 경우 비우기
      let shop = form.shop.length === this.shop.length ? [] : form.shop.map(e=>e.shop_id); // 전체 선택일 경우 비우기
      let brand = form.brand.map(e=>e.value);
      let category = form.category.map(e=>e.value);
      let delay = form.delay.length === (this.$C.DELAY_CODE.length + 1) ? [] : form.delay; // 전체 선택일 경우 비우기
      let goods_no = this.goods_no ? this.goods_no.split(/\D+/g).map(e=>+e) : [];
      let order_no = this.order_no ? this.order_no.split(/\r?\n/g).map(e=>e.trim()) : [];
      const body = {goods_no, order_no, form: {...form, mall, shop, brand, category, delay}};
      if (this.query.trim()) {
        try {
          eval('body.form._query = {' + this.query + '}');
        } catch (e) {}
      }
      const j = await this.$api.postTable(this, '/order/deliver_board', body, {more, fnAssign: this.assignTableData});

      // 검색결과중 검색필드와 주문번호, oid 가 일치하는 주문이 있다면 url 을 바꿔준다.
      if (j && j.list.find(e=>e.orderno === form.search || e.oid === form.search) && location.hash === '#/deliveryBoard') {
        history.replaceState(null, null, location.origin + '/#/deliveryBoard/' + this.form.search.replace('|', '/'));
      }
    },
    assignTableData(e) {
      if (e.b_rank) {
        e.b_rank = e.b_rank < 3 ? e.b_rank : '그외';
      }
      let ps_log = e.ps_log && this.$utils.typeOf(e.ps_log) === 'array' ? e.ps_log.filter(e=>!e._del_dt) : [];
      let op_log = e.op_log ? e.op_log.filter(e=>!e._del_dt) : [];
      let mng_log = e.mng_log ? e.mng_log.filter(e=>!e._del_dt) : [];
      let mallObj = this.mallMap[e.mall];
      // mall 이 롯데아이몰이고 주문일자가 20.03.12 이전이라면 20% 의 수수료로 계산한다.
      if (e.mall === '롯데아이몰' && e.order_date < '2020-03-12') mallObj.fee = 20;
      let settle_price = e.sales_price * (100 - (mallObj.fee || 0)) / 100;

      let info = {}; // inflow 등의 추가 정보
      if (e.mall === '고도몰' && e.mall_info) {
        /**
         * 발란몰 결제시 가상계좌 등으로 가격인하 후 계산한다면 원래 대상금액이 얼마였는지는 알 수 없다.
         * 해당 시각 gd_goods 등의 가격을 참고할수밖에 없다.
         * 따라서 안분배당은 전체 goods_price 대비 상품별 sales_price 비율 로 계산한다. 3자리 올림에 의한 오차가 발생한다.
         */
        // let goods_price = e.option ? e.option.goods_price : e.sales_price; // 오래된 주문에 option 이 없는 경우가 있다.
        // 전체 결제중 해당상품 비중
        let goods_ratio = e.sales_price / (e.mall_info.settleprice + e.mall_info.emoney + e.mall_info.coupon); // sales_price = settleprice + coupon + emoney
        let goods_price = this.$utils.round(goods_ratio * e.mall_info.goodsprice);
        let settlekind = this.$C.SETTLE_KIND_GODO[e.mall_info.settlekind] || {name: ''};
        let payment_fee = (settlekind.fee || 0) / 100 * e.mall_info.settleprice + (settlekind.feeWon || 0);

        e.goods_price = goods_price;
        e.settlekind_name = settlekind.name;
        e.emoney_divided = this.$utils.round(goods_ratio * e.mall_info.emoney);
        e.coupon_amount_divided = this.$utils.round(goods_ratio * e.mall_info.coupon);
        e.discount_price = this.$utils.round(goods_price - e.coupon_amount_divided - e.emoney_divided);
        e.payment_amount = this.$utils.round(e.mall_info.settleprice * goods_ratio);
        settle_price = (e.mall_info.settleprice - payment_fee) * goods_ratio + e.emoney_divided;
        info.inflow = e.mall_info.inflow;
        info.pg = (e.mall_info.pg || 'INIPAY').toUpperCase();
      }
      if (e.mall === '스토어팜 (발란)' && e.mall_info) {
        info.inflow = e.mall_info.InflowPath;
        e.settlekind_name = e.mall_info.PaymentMeans;
        info.pg = 'SS';
      }
      if (e.mall === '글로벌' && e.channel === 'Shopify') {
        info.pg = e.mall_info.payment_gateway_names[0];
      }
      if (e.price_table) {
        // e.supply_pg_removed = e.price_table.prRule ? e.price_table.priceRebateRule.price * (100 - (e.price_table.rebate_rate || 0)) / 100 : Math.round(e.price_table.supply.finalPriceWithoutPg);
        e.supply_pg_removed = Math.round(e.price_table.supply.finalPriceWithoutPg);
        e.supply_pg_removed_qty = e.supply_pg_removed * e.qty; // PG 엑셀 다운로드용
        e.margin = settle_price + (e.coupon_amount_divided || 0) - e.supply_pg_removed * e.qty;
        e.margin_coupon = settle_price - e.supply_pg_removed * e.qty;
        // e.margin = settle_price - (e.price_table.supply.finalPrice - (mallObj.feeIncludesPg === 'y' ? e.price_table.supply.pgFee : 0)) * e.qty;
        e.margin_rate = (e.margin > 0 ? e.margin / e.qty / e.price_table.finalPriceRoundUp : e.margin / e.qty / e.price_table.supply.finalPriceRoundUp) * 100;
        e.margin_coupon_rate = (e.margin_coupon > 0 ? e.margin_coupon / e.qty / e.price_table.finalPriceRoundUp : e.margin_coupon / e.qty / e.price_table.supply.finalPriceRoundUp) * 100;
      }
      let cancel_price = ~['cancel_request','cancel_complete','refund_request','refund_complete','refund_partner','refund_partner_ipgo'].indexOf(e.order_status_code) ? e.sales_price : null;
      let shop = this.shopMap[e.shop_id];
      if (shop) {
        e.deliveryDay = shop.delivery_type === 'both' ? shop[{해외: 'delivery_day_abroad', 국내: 'delivery_day'}[e.delivery_type]] : shop.delivery_day;
        e.delivery_str = e.delivery_type + (shop.logistics === 'direct' ? (e.delivery_type === '해외' && shop.pccc_feed === 'y' ? '구매대행' : '직배송') : '');
      } else {
        this.$alertTop(`${e.goodsno} 상품의 shop 정보가 없습니다. 예상배송일을 알 수 없습니다.`);
        e.deliveryDay = 21;
        e.delivery_str = 'SHOP배송정보없음';
      }

      // const dt = moment().format('YYYY-MM-DD HH:mm:ss');
      const paymentTime = e.payment_dt.slice(11, 16);
      // const nextDay = momentBiz(e.payment_date).add(1, 'day').format('YYYY-MM-DD');
      const biz1Day = momentBiz(e.payment_date).nextBusinessDay().format('YYYY-MM-DD');
      // const biz1NextDay = momentBiz(e.payment_date).nextBusinessDay().add(1, 'day').format('YYYY-MM-DD');
      // 마감시간: 주문접수마감, 오늘도착은 13시, 오늘출발은 상품별 상이
      // 발송기한: 발송처리마감, 오늘도착은 14시, 오늘출발은 23:59:59
      // 지연기준: 오늘도착은 발송기한 익일 새벽 05:00 까지 배송완료가 아닌 주문, 오늘출발은 발송기한 당일 23:59:59 까지 송장이 등록되지 않은 주문
      const odLimit = e.order_dt <= '2022-03-31 ' + onedayDeliveryLimit ? onedayDeliveryLimit : onedayDeliveryLimitNext;

      const ODLimitDay = paymentTime <= odLimit ? e.payment_date : biz1Day; // 마감시간일 = 발송기한일
      const ODLimitDT = (ODLimitDay + ' ' + odLimit).slice(2, 16); // 마감시간
      // const ODStartDT = (ODLimitDay + ' ' + onedayDeliveryStart).slice(2, 16); // 발송기한
      // const ODDelayDT = paymentTime <= odLimit ? nextDay + ' 05:00:00' : biz1NextDay + ' 05:00:00'; // 지연기준
      // const TPLimitDay = paymentTime.slice(0, 5) <= e.today_pick_time ? e.payment_date : biz1Day; // 발송기한일
      // const TPLimitDT = (TPLimitDay + ' ' + e.today_pick_time).slice(2); // 마감시간
      // const TPDelayDT = TPLimitDay + ' 23:59:59'; // 지연기준
      // const TPStartDT = TPDelayDT.slice(2, 16); // 발송기한
      e._badgeOnedayDelivery = e.oneday_delivery ?
        //`<span class="badge alert-danger">오늘도착 ${e.payment_dt.split(' ')[1] <= odLimit ? '[오늘]' : '[내일]'}</span><br/>` +
        `<span class="badge alert-danger">오늘도착</span><br/>` +
        `<small>${ODLimitDT}</small><br/>` :
        '';
      e._badgeTodayPick = e.today_pick ?
        //`<span class="badge alert-success">오늘출발 ${e.payment_dt.split(' ')[1] <= e.today_pick_time + ':00' ? '[오늘]' : '[내일]'}</span><br/>` +
        `<span class="badge alert-success">오늘출발</span><br/>` +
        `<small>${e.order_receive_date} ${e.today_pick_time}</small><br/>` +
        `<small>${e.today_pick_deadline}</small><br/>` :
        '';
      e._badgeOrderMade = e.orderMade ? '<span class="mr-1 badge alert-secondary">주문제작</span>' : '';
      e._badgeDeliveryExtra = e.deliveryExtra && e.deliveryExtra.length ? e.deliveryExtra.map(extra => `<span class="mr-1 badge alert-secondary">${e.delivery_type} ${this.deliveryExtraMap[extra]}</span>`).join('<br/>') : '';
      // e._badgeODDelay = e.oneday_delivery && dt > e.oneday_delivery_deadline && !e.del_complete_date ?
      //   `<span class="badge alert-danger">오늘도착 [지연]</span><br/>` :
      //   '';
      // e._badgeTPDelay = e.today_pick && dt > TPDelayDT && (e.order_status_code.in('order_receive', 'del_prepare') || !e.domestic_invoice_no) ?
      //   `<span class="badge alert-success">오늘출발 [지연]</span><br/>` :
      //
      e._badgePurchase = e.orderType === 'purchase' ? `<span class="badge alert-primary">매입계산서</span><br/>` : '';

      const curYear = new Date().getFullYear() + '';
      const reduceDT = dt => dt.slice(0, 16).slice(dt.slice(0, 4) === curYear ? 5 : 0);

      // let orderno = {
      //   '고도몰': `<a href="${this.ADMIN_URL}/#/orders/list?ordno=${e.orderno}" target="_blank">${e.orderno}</a><br/>${e.order_detailno}`,
      //   '스토어팜 (발란)': `<a href="https://sell.smartstore.naver.com/o/orderDetail/productOrder/${e.orderno}/popup" target="_blank">${e.orderno}</a><br/>${e.order_detailno}`,
      // }[e.mall] || `${e.orderno}<br/>${e.order_detailno}`;
      const mallWithLink = {
        '고도몰': `<a href="${this.ADMIN_URL}/#/orders/list?ordno=${e.orderno}" class="badge badge-primary" target="_blank">${e.mall}</a>`,
        '스토어팜 (발란)': `<a href="https://sell.smartstore.naver.com/o/orderDetail/productOrder/${e.orderno}/popup" class="badge badge-success" target="_blank">${e.mall}</a>`,
      }[e.mall] || `<span class="badge badge-light">${e.mall}</span>`;
      const orderno = `<span class="text-primary pointer" onclick="_deliBoardShowModal('${e.oid}')">${e.orderno}</span><br/>${e.order_detailno}`;

      // const mallBadge = `<span class="badge badge-${e.mall === '고도몰' ? 'dark' : e.mall === '스토어팜 (발란)' ? 'success' : 'primary'}">${e.mall === '고도몰' ? '발란몰' : e.mall}</span>`;
      const CFStoreGiftBadge = [e.cf_gift_packing && '<span class="badge badge-danger">포장</span>',
        e.cf_store_pickup && '<span class="badge badge-primary">픽업</span>'].filter(e => e).join(' ');
      const storePickupDate = (e.store_pickup_date || '').replace(/-/g, '');
      if (storePickupDate) {
        e.storePickupDateStr = `${storePickupDate.slice(2, 4)}-${storePickupDate.slice(4, 6)}-${storePickupDate.slice(6, 8)} 23:59`;
      } else {
        e.storePickupDateStr = '';
      }
      const storeGiftBadge = [e.gift_packing && '<span class="badge badge-danger">포장</span>',
        e.store_pickup && `<span class="badge badge-primary">픽업</span><br/><small>${e.storePickupDateStr}</small>`].filter(e => e).join('<br/>');

      e.hs_code = (this.$C.CATEGORY_HSCODE_MAP[e.category] && this.$C.CATEGORY_HSCODE_MAP[e.category].hs_code) || '';

      Object.assign(e, {
        info,
        cancel_price,
        mall_fee: mallObj.fee || 0,
        settle_price,
        marginRule: e.price_table && e.price_table.marginRule ? JSON.stringify(e.price_table.marginRule) : '',
        img: e.img_urls ? e.img_urls[0] : '',
        html1: `${mallWithLink}${ e.ec_order_code ? ` <span class="badge badge-light">EC</span>` : ''}` + `${orderno}`,
        html2: this.makeShopBadge(shop) + ' ' + this.makeBrandBadge(e.brand_no, e.brand, {type: this.brandMap[e.brand_no].brand_type}) + ' ' +
          this.makeGoodsNoLinkBadge(e.goodsno, {goodsType: e.goodsType}) + '<br/>' + e.goodsnm +
          `<br/><span class="badge badge-light">${e.size}</span><span class="badge badge-info">${e.qty}</span>`,
        html3: `${e.orderer.masker({intent: 'middle'})}<br/>${e.receiver.masker({intent: 'middle'})}`,
        html6: `<span title="주문자연락처: ${e.orderer_phone.masker({intent: 'phone'})}\n주문자휴대폰: ${e.orderer_mobile.masker({intent: 'phone'})}`
          + `\n수령자연락처: ${e.receiver_phone.masker({intent: 'phone'})}\n수령자휴대폰: ${e.receiver_mobile.masker({intent: 'phone'})}">`
          + `${e.orderer_mobile.masker({intent: 'phone'})}<br/>${e.receiver_mobile.masker({intent: 'phone'})}</span>`,
        // 상품속성
        html4: `<span class="badge alert-${e.delivery_type==='해외'?'success':'info'}">${e.delivery_str}</span>`
          + (!e.cf_order_made && (!e.cf_delivery_extra || !e.cf_delivery_extra.includes('long')) ? `<span class="badge badge-light">${e.deliveryDay}일</span><br/>` : '')
          + `${e.manual ? '<span class="badge alert-danger ml-1">파트너관리</span>' : ''}<br/>`
          + (e.cf_oneday_delivery === 'Y' ? `<span class="badge alert-danger ml-1">오늘도착 가능</span><br/>` : '')
          + (e.cf_today_pick ? `<span class="badge alert-success ml-1">오늘출발 가능</span><br/>` : '')
          + (CFStoreGiftBadge ? CFStoreGiftBadge + '<br/>' : '')
          + `${e.goodsType === 'used' ? `<span class="badge alert-warning">빈티지</span><span class="badge badge-light">${e.usedGrade}</span>` : ''}`
          + `${e.cf_order_made ? `<span class="badge alert-secondary">주문제작</span>` : ''}`
          + `${e.cf_delivery_extra && e.cf_delivery_extra.length ? `<span class="badge alert-secondary">${e.delivery_type} 가구배송</span>` : ''}`,

        // 주문속성
        html5: `<span class="badge alert-info">${info.pg || ''}</span>`
          + (info.pg === 'NAVERPAY' ? '' : `<span class="badge alert-dark">${e.settlekind_name || ''}</span>`)
          + `<br/>`
          + e._badgeOnedayDelivery + e._badgeTodayPick
          + (storeGiftBadge ? storeGiftBadge + '<br/>' : '')
          + `${e.withdraw_flag ? `<span class="badge alert-warning" title="${e.withdraw_flag._dt}">철회이력</span><br/>` : ''}`
          + (e.changeOrder || []).sort((a, b) => {
            if (this.orderChangeCodeMap[a].code === 'REPAYMENT') return -1;
            if (this.orderChangeCodeMap[b].code === 'REPAYMENT') return 1;
            return 0;
          }).map(m => {
            switch (this.orderChangeCodeMap[m].code) {
            case 'OPTIONS':
              return `<span class="badge alert-success">${this.orderChangeCodeMap[m].name}</span>`;
            case 'ADDRESS':
              return `<span class="badge alert-success">${this.orderChangeCodeMap[m].name}</span>`;
            case 'REPAYMENT':
              return `<span class="badge alert-danger">${this.orderChangeCodeMap[m].name}</span><br/>
                      <a href="${this.ADMIN_URL}/#/orders/list?ordno=${e.repaymentOrderNo}" target="_blank">${e.repaymentOrderNo}</a><br/>`;
            default:
              return ``;
            }
          }).join('') + '<br/>'
          + `${e.ordershift ? `<br/><span class="badge alert-danger pointer" onclick="alert('타 판매자의 품절, 배송지연으로 인해 현 판매자의 동일 상품으로 교체된 주문입니다.')">
            교체된 주문<i class="ml-1 fa fa-question-circle"></i></span><br/>` : ''}`
          + e._badgePurchase
          + e._badgeOrderMade
          + e._badgeDeliveryExtra,
        // 지연상태
        html7:
          // e._badgeODDelay + e._badgeTPDelay +
          ((e.delay || []).concat(e.delay_refund || [])).map(c =>
          `<span class="badge badge-danger" title="${this.delayCodeMap[c] && this.delayCodeMap[c].desc || c}">${this.delayCodeMap[c] && this.delayCodeMap[c].name || ''}</span>`
        ).join('<br/>'),
        lambda2: e=>`<small class="text-truncate d-inline-block w-100" title="${(e.ps_memo || '').escapeHtml()}">${e.ps_memo || ''}</small><br/>`
          + `${ps_log.length ? `<span class="badge badge-warning" title="PS Log\n${ps_log.map(e=>`${e._name||'?'}: ${e.content}`).join('\n').escapeHtml()}">${ps_log.length}</span>`: ''}`
          + `${op_log.length ? `&nbsp;<span class="badge badge-info" title="운영 Log\n${op_log.map(e=>`${e._name||'?'}: ${e.content}`).join('\n').escapeHtml()}">${op_log.length}</span>`: ''}`
          + `${mng_log.length ? `&nbsp;<span class="badge badge-success" title="주문관리 Log\n${mng_log.map(e=>`${e._name||'?'}: ${e.content}`).join('\n').escapeHtml()}">${mng_log.length}</span>`: ''}`
          + '<br/>'
          + `<span class="badge badge-light" title="${(e.claim_memo || '').escapeHtml()}">${e.claim_memo || ''}</span><br/>`,
        lambda3: e => `${this.$utils.comma(e.margin_table ? e.margin_table.standardPriceSale : 0)}<br/>`
            + ( this.$R('GOODS_R') ? `${this.$utils.comma(e.margin_table ? e.margin_table.mall.memberPrice : 0)}<br/>`
                + `${this.$utils.comma(e.margin_table ? e.margin_table.mall.orderSettlePrice : 0)}`
              : ''),
        lambda1: e=>`<span title="${e.order_dt}">${reduceDT(e.order_dt || e.order_date)}</span><br/>
          <span class="badge badge-light">${e.order_status}</span><br/>

          <span title="${e[e.order_status_code+'_dt']}">${reduceDT(e[e.order_status_code+'_dt'] || e[e.order_status_code+'_date'])}</span>`,
      });
    },
    toggleUsedGrade(grade) {
      if (!grade) {
        this.form.usedGrade = this.form.usedGrade.length === this.$C.USED_GRADE.length ? [] : this.$C.USED_GRADE.map(e => e.value);
      } else {
        this.form.usedGrade = this.$C.USED_GRADE.filter(e => e.value[0] === grade).map(e => e.value);
      }
    },
    async setOrderStatusAllModal() {
      let selectedItems = this.items.list.filter(e=>e.selected);
      if (!selectedItems.length) return alert('상태를 변경할 주문을 선택해주세요');
      this.modal.statusAll = true;
    },
    async setOrderStatusAll() {
      const code = this.statusCodeMap[this.statusAllCode].code;
      if (!process.env.IS_DEV && !this.$R('ADMIN') && code === 'del_prepare' && this.item.order_status_code === 'order_receive') {
        alert(`배송 준비 상태로 직접 변경할 수 없습니다. 개발팀에 문의해 주세요.`);
        return;
      }
      // claim_memo 가 필요한 상태라면 검증한다.
      if (~Object.keys(this.$C.CLAIM_SET).indexOf(code)) {
        this.formClaim.toCode = code;
        this.formClaim.claim_set = this.$C.CLAIM_SET[code];
        this.formClaim.claim_memo = ''; // 이동할 상태에도 동일한 클레임사유가 있을 때만 남겨둔다.
        this.formClaim.status_date = this.$moment().format('YYYY-MM-DD');
        this.modal.claim = true;
        return;
      }
      let selectedItems = this.items.list.filter(e=>e.selected).map(e=>({oid: e.oid}));
      if (confirm(`${selectedItems.length} 개의 주문을 ${this.statusCodeMap[this.statusAllCode].name} 상태로 변경하시겠습니까?`)) {
        let j = await this.$api.postJson('/order/setOrderStatusAll', {orders: selectedItems, status_code: this.statusAllCode, date: this.statusAllDate});
        if (!j) return;
        this.list();
        this.$utils.alert('상태 변경을 완료했습니다');
      }
    },
    async setOrderStatusClaim() {
      this.busy.claim = true;
      let form = this.formClaim;
      if (!form.claim_memo) return alert('클레임사유를 입력해주세요');
      let selectedItems = this.items.list.filter(e=>e.selected).map(e=>({oid: e.oid}));
      if (confirm(`${selectedItems.length} 개의 주문을 ${this.statusCodeMap[this.statusAllCode].name} 상태로 변경하시겠습니까?`)) {
        let j = await this.$api.postJson('/order/setOrderStatusAll', {orders: selectedItems, status_code: this.statusAllCode, date: this.statusAllDate, claim_memo: form.claim_memo});
        if (!j) return;
        this.busy.claim = false;
        this.list();
        this.$utils.alert('상태 변경을 완료했습니다');
      }
      this.modal.claim = false;
    },
    async sendKakaoAll() {
      let selectedItems = this.items.list.filter(e=>e.selected);
      if (!selectedItems.length) return alert('알림톡을 발송할 주문을 선택해주세요');
      let invalidStatusItems = selectedItems.filter(e=>!~['del_prepare','del_abroad','del_abroad_received','release_ready','del_domestic','del_complete'].indexOf(e.order_status_code));
      if (invalidStatusItems.length) {
        this.$utils.alert('다음 주문들의 상태가 바르지 않습니다.\n' + invalidStatusItems.map(e=>`${e.orderno}:${e.order_detailno} => ${e.order_status}`).join('\n'),
          '알림톡 발송에 실패했습니다');
        return;
      }
      let ordnos = selectedItems.map(e=>`${e.orderno}:${e.order_detailno}`);
      let j = await this.$api.postJson('/order/sendKakao', {ordnos});
      if (j) {
        this.$utils.alert(j.msg || '알림톡 발송에 성공했습니다');
      }
    },
    // 23-01-10 어드민 페이지 admin.balaan.io로 이전하면서 쓰지 않는 기능 정리
    // async setWithdrawAll() {
    //   let selectedItems = this.items.list.filter(e=>e.selected);
    //   if (!selectedItems.length) return alert('환불처리할 주문을 선택해주세요');
    //   let invalidStatusItems = selectedItems.filter(e=>e.order_status_code !== 'withdraw' && e.order_status_code !== 'cancel_request');
    //   if (invalidStatusItems.length) {
    //     this.$utils.alert('주문들은 품절 혹은 취소요청 상태여야 합니다. 다음 주문들의 상태가 다릅니다.\n' + invalidStatusItems.map(e=>`${e.orderno}:${e.order_detailno} => ${e.order_status}`).join('\n'),
    //       '품절 처리에 실패했습니다');
    //     return;
    //   }
    //   if (!confirm(`${selectedItems.length} 개의 주문에 대해서 환불처리를 진행하시겠습니까?`)) return;
    //   let j = await this.$api.postJson('/order/processWithdraw', {orders: selectedItems.map(e => ({id: e._id,
    //       orderno:e.orderno, order_detailno:e.order_detailno, order_status_code:e.order_status_code}))});
    //   if (!j) return;
    //   this.$utils.alert(j.data, '환불 처리를 요청했습니다');
    // },
    async setDelPrepare() {
      let selected = this.items.list.filter(e=>e.selected);
      if (!confirm(`선택한 주문들을 배송준비 상태로 변경하시겠습니까? 각각의 몰에서 배송준비, 발주확인, 발송약정 상태로 변경됩니다`)) return;
      this.busy.placeOrder = true;
      let j = await this.$api.postJson('/order/placeOrder', {oids:selected.map(e=>e.oid)});
      this.busy.placeOrder = false;
      if (j) {
        j.updates && j.updates.forEach(e=>{
          let item = selected[e.idx];
          e.$set && Object.entries(e.$set).forEach(([k, v]) => {
            item[k] = v;
          });
          e.$unset && Object.entries(e.$unset).forEach(([k, v]) => {
            delete item[k];
          });
          e.$push && Object.entries(e.$push).forEach(([k, v]) => {
            if (!item[k]) item[k] = [];
            item[k].push(v);
          });
          this.assignTableData(item);
        });
        alert(`${j.success} 건의 주문을 배송준비 상태로 변경했습니다.${j.issue ? `\n${j.issueOrders.map(e=>`${e.oid}: ${e.msg}`).join('\n')}` : ''}`);
      }
    },
    async down(type, excelForm) {
      let delay_map = {};
      this.$C.DELAY_CODE.forEach(e=> delay_map[e.code] = e.name);
      let selectedItems = this.items.list.filter(e => e.selected);
      if (!selectedItems.length) return alert('다운받을 주문을 선택해주세요');

      const reasonSelected = await this.checkReason();
      if (!reasonSelected) return;

      const COLS = this.$C.DELIVER_BOARD_COLUMNS.filter(e => e.download)
        .filter(e => !e.code.in('today_pick', 'today_pick_time'))
        .filter(e => !e.code.startsWith('customs_')); // 220512 통관관련 정보 제거 with 미나님 요청

      selectedItems.forEach(e=> {
        e.abroad_direct = e.delivery_type === '해외' ? (e.abroad_direct ? 'Y' : 'N') : '';
        if (e.delay) e.delay = e.delay.map(d=> delay_map[d]).join(',');
        if (e.delay_refund) e.delay_refund = e.delay_refund.map(d=> delay_map[d]).join(',');
        if (e.confirmation) e.confirmation_date = e.confirmation.dt.split(' ')[0];
        e._ps_log = e.ps_log ? e.ps_log.filter(e=>!e._del_dt).map(e=>`${e._dt} [${e._name}] ${e.content}`).join('\n') : '';
        e._op_log = e.op_log ? e.op_log.filter(e=>!e._del_dt).map(e=>`${e._dt} [${e._name}] ${e.content}`).join('\n') : '';
        e._mng_log = e.mng_log ? e.mng_log.filter(e=>!e._del_dt).map(e=>`${e._dt} [${e._name}] ${e.content}`).join('\n') : '';
        if (excelForm === 'boutique') e.boutique_oid = [e.boutique, e.orderno, e.order_detailno].join('_');
      });

      // let name = COLS.map(e => e.name).concat(['PS Log', 'OP Log', '주문관리 Log', 'PG', '결제수단']);
      const headerMap = {
        'price_table.consumer.finalPrice': '소비자가',
        'price_table.mappedPrice': '원본가',
        'price_table.sale.localPrice': '세전현지가',
        'price_table.priceLimitRule.priceLimit': '가격고정가',
        'price_table.sale.standardPrice': '판매기준가',
        'price_table.finalPriceRoundUp': '발란회원가',
        'mall_info.goodsPrice': '발란몰 단위 판매 금액',
        'mall_info.coupon': '쇼핑백쿠폰',
        'margin_table.mall.memberPrice': '최대혜택가',
        'emoney_divided': '적립금 사용액',
        'margin_table.mall.orderSettlePrice': '실결제금액',
      }

      let name = COLS.map(e => e.name).concat('PS Log,OP Log,주문관리 Log,PG,결제수단,통화'.split(','));
      let code = COLS.map(e => e.code).concat(['_ps_log', '_op_log', '_mng_log', 'info.pg', 'settlekind_name', 'price_table.currency']);
      Object.entries(headerMap).forEach(([k, v]) => {
        name.push(v);
        code.push(k);
      });
      // 오늘출발 발송기한 추가
      let addColumns = [{
        before: '오늘도착여부',
        name: '오늘출발 발송기한',
        code: 'today_pick_deadline'
      }, {
        before: '교환완료일',
        name: '구매확정일',
        code: 'confirmation_date'
      }, {
        before: 'B랭크',
        name: 'B class',
        code: 'b_rank_class'
      }];

      // 부티크 엑셀 형식: 기본형식에서 신규 컬럼 추가(boutique_orderno_order_detailno, category, org_category, hs_code, origin)
      if (excelForm === 'boutique') {
        addColumns = [...addColumns, {
          before: '부주문코드',
          name: '부티크 주문코드',
          code: 'boutique_oid'
        }, {
          before: 'SKU',
          name: '카테고리코드',
          code: 'category'
        }, {
          before: '카테고리코드',
          name: '카테고리명',
          code: 'org_category'
        }, {
          before: '카테고리명',
          name: 'HS-CODE',
          code: 'hs_code' //TODO: 향후 추가될 컬럼명 확인(임시매칭)
        }, {
          before: 'HS-CODE',
          name: '원산지',
          code: 'origin'
        }]
      }

      addColumns.forEach(addCol => {
        const idx = name.indexOf(addCol.before) + 1;
        name.splice(idx, 0, addCol.name);
        code.splice(idx, 0, addCol.code);
      });

      let downloadName = excelForm === 'boutique' ? `DeliverBoardBoutique_${this.$utils.dt()}` : `DeliverBoard_${this.$utils.dt()}`
      down(selectedItems, name, code, downloadName, type);
      this.sendPrivateLog('down', {type, excelForm}, '다운로드', selectedItems.length);
    },
    async downWithCalc() {
      let selectedItems = this.items.list.filter(e=>e.selected);
      if (!selectedItems.length) return alert('다운받을 주문을 선택해주세요');

      const reasonSelected = await this.checkReason();
      if (!reasonSelected) return;

      let name = this.$C.DELIVER_BOARD_COLUMNS.filter(e=>e.download).map(e=>e.name).concat(['이미지','외부몰수수료','정산금액']);
      let code = this.$C.DELIVER_BOARD_COLUMNS.filter(e=>e.download).map(e=>e.code).concat(['img_urls.0','mall_fee','settle_price']);

      let dupFields = {
        basePrice:'기본현지가(원가)',
        ivaPrice:'iva현지가(iva조정후)',
        localRate:'할인할증률',
        localPrice:'세전현지가(할인할증후)',
        befTaxPriceWithoutDelivery:'세전KRW(배송비전)',
        localDeliveryCost:'해외배송비KRW',
        befTaxPrice:'배송비포함KRW',
        cTax:'관세',
        idvTax:'개소세',
        eduTax:'교육세',
        idvEduTax:'개소세교육세합',
        vatKrw:'부가세',
        vatPrice:'세후가격',
        rebatePrice:'판매수수료적용가격',
        pgFee:'PG수수료',
        aftPgPrice:'PG수수료포함가격',
        domestic_delivery_cost:'국내배송비',
        finalPriceWithoutVatPg:'VAT&PG제외가격',
        finalPriceWithoutPg:'PG제외가격',
        finalPrice:'국내가격',
        finalPriceRoundUp:'국내가격올림',
      };
      let lastFields = {
        currency:'통화종류',
        customs_rate:'관세율',
        vat:'부가세율',
        idvEduRate:'개소세교육세율',
        pgRate:'PG수수료',
        rebate:'판매수수료KRW',
        befMargin:'수정전마진',
        margin:'수정마진',
        marginRate:'수정마진율',
        marginRule:'마진규칙',
        discountRate:'할인율',
        finalPrice:'최종판매가',
        finalPriceRoundUp:'최종판매가RoundUp',
        finalMargin:'최종마진',
        finalMarginRate:'최종마진율',
      };
      let fields = code.concat('consumer,sale,newin,supply'.split(',').map(e=>Object.keys(dupFields).map(k=>`price_table.${e}.${k}`).join(','))).concat(Object.keys(lastFields).map(k=>`price_table.${k}`).join(',')).join(',') + ',marginRule';
      let header = name.concat('consumer,sale,newin,supply'.split(',').map(e=>Object.values(dupFields).map(k=>`${e}_${k}`).join(','))).concat(Object.values(lastFields).join(',')).join(',') + ',마진규칙';
      down(selectedItems, header.split(','), fields.split(','), `DeliverBoardWithCalc_${this.$utils.dt()}`, 'xlsx');
      this.sendPrivateLog('downWithCalc', {}, '다운로드', selectedItems.length);
    },
    // async downSimple(type) {
    //   /**
    //    * 구매대행용 양식
    //    */
    //   const selected = this.items.list.filter(e => e.selected);
    //   selected.forEach(e => e._ps_log = e.ps_log ? e.ps_log.filter(e => !e._del_dt).map(e => `${e._dt} [${e._name}] ${e.content}`).join('\n') : '');
    //   selected.forEach(e => e._op_log = e.op_log ? e.op_log.filter(e => !e._del_dt).map(e => `${e._dt} [${e._name}] ${e.content}`).join('\n') : '');
    //   selected.forEach(e => e._mng_log = e.mng_log ? e.mng_log.filter(e => !e._del_dt).map(e => `${e._dt} [${e._name}] ${e.content}`).join('\n') : '');
    //   if (!selected.length) return alert('다운받을 주문을 선택해주세요');
    //
    //   let titles = '주문접수일,쇼핑몰 주문코드(PK),일련번호(PK),구매링크,자체상품코드,선택사항,수량,브랜드,상품명,주문상세상태,원가,파트너 주문코드,구매처(파트너),파트너 상품코드,주문코드,부주문코드,쇼핑몰,채널,주문자,주문자 전화번호,주문자 휴대폰번호,주문일,수령자 전화번호,수령자 휴대폰번호,우편번호,주소,배송기재사항,개인통관고유부호,택배사,운송장번호,수령자,쇼핑몰 상품코드,,총판매액,SKU,해외택배사,해외운송장,리얼패킹id,PS메모,배송준비일,품절일,해외배송일,해외배송완료일,물류입고일,출고대기일,입고차수,파트너반품일,파트너반품(입고)일,국내배송일,배송완료일,취소요청일,취소확인일,반품요청일,반품완료일,교환요청일,교환완료일,회수접수일'.split(',');
    //   let cols = this.$C.DELIVER_BOARD_COLUMNS.filter(e => ~titles.indexOf(e.name));
    //   cols.sort((a, b) => titles.indexOf(a.name) - titles.indexOf(b.name));
    //
    //   let header = cols.map(e => e.name).concat(['PS Log', 'OP Log', '주문관리 Log']);
    //   let fields = cols.map(e => e.code).concat(['_ps_log', '_op_log', '_mng_log']);
    //
    //   // 브랜드 이전에 넣는다
    //   let idx = header.indexOf('브랜드');
    //   header.splice(idx, 0, 'supplyBasePrice');
    //   fields.splice(idx, 0, 'price_table.supply.basePrice');
    //
    //   down(selected, header, fields, `DeliverBoardSimple_${this.$utils.dt()}`, type);
    // },
    // async downWebsite(type) {
    //   const selected = this.items.list.filter(e => e.selected);
    //   selected.forEach(e => e._ps_log = e.ps_log ? e.ps_log.filter(e => !e._del_dt).map(e => `${e._dt} [${e._name}] ${e.content}`).join('\n') : '');
    //   selected.forEach(e => e._op_log = e.op_log ? e.op_log.filter(e => !e._del_dt).map(e => `${e._dt} [${e._name}] ${e.content}`).join('\n') : '');
    //   selected.forEach(e => e._mng_log = e.mng_log ? e.mng_log.filter(e => !e._del_dt).map(e => `${e._dt} [${e._name}] ${e.content}`).join('\n') : '');
    //   if (!selected.length) return alert('다운받을 주문을 선택해주세요');
    //
    //   let titles = '주문접수일,브랜드,상품명,주문상세상태,원가,파트너 주문코드,선택사항,수량,구매링크,쇼핑몰 주문코드(PK),구매처(파트너),파트너 상품코드,주문코드,부주문코드,일련번호(PK),쇼핑몰,채널,주문자,주문자 전화번호,주문자 휴대폰번호,주문일,수령자 전화번호,수령자 휴대폰번호,우편번호,주소,배송기재사항,개인통관고유부호,택배사,운송장번호,수령자,쇼핑몰 상품코드,자체상품코드,총판매액,SKU,해외택배사,해외운송장,리얼패킹id,PS메모,배송준비일,품절일,해외배송일,해외배송완료일,물류입고일,출고대기일,입고차수,파트너반품일,파트너반품(입고)일,국내배송일,배송완료일,취소요청일,취소확인일,반품요청일,반품완료일,교환요청일,교환완료일,회수접수일'.split(',');
    //   let cols = this.$C.DELIVER_BOARD_COLUMNS.filter(e => ~titles.indexOf(e.name));
    //   cols.sort((a, b) => titles.indexOf(a.name) - titles.indexOf(b.name));
    //
    //   let header = cols.map(e => e.name).concat(['PS Log', 'OP Log', '주문관리 Log']);
    //   let fields = cols.map(e => e.code).concat(['_ps_log', '_op_log', '_mng_log']);
    //   down(selected, header, fields, `DeliverBoardWebsite_${this.$utils.dt()}`, type);
    // },
    // async downExpress(type) {
    //   const selected = this.items.list.filter(e => e.selected);
    //   if (!selected.length) return alert('다운받을 주문을 선택해주세요');
    //
    //   const titles = '쇼핑몰 주문코드(PK),일련번호(PK),쇼핑몰,주문자 휴대폰번호,주문상세상태,주문접수일,우편번호,주소,자체상품코드,총판매액,브랜드,ShopID,배송유형,직배송여부,오늘도착여부'.split(',');
    //   let cols = this.$C.DELIVER_BOARD_COLUMNS.filter(e => titles.includes(e.name));
    //
    //   let header = cols.map(e => e.name).concat(['오늘출발 발송기한']);
    //   let fields = cols.map(e => e.code).concat(['today_pick_deadline']);
    //
    //   down(selected, header, fields, `DeliverBoardExpress_${this.$utils.dt()}`, type);
    // },
    // async downOptions(type) {
    //   const selected = this.items.list.filter(e => e.selected);
    //   if (!selected.length) return alert('다운받을 주문을 선택해주세요');
    //
    //   let fields = 'order_dt,order_receive_dt,mall,goodsno,option.Size,qty,sales_price,withdraw_dt,cancel_request_dt,cancel_complete_dt'.split(',');
    //   down(selected, null, fields, `DeliverBoardOptions_${this.$utils.dt()}`, type);
    // },
    async downPG() {
      const selected = JSON.parse(JSON.stringify(this.items.list.filter(e => e.selected)));
      if (!selected.length) return alert('다운받을 주문을 선택해주세요');

      const reasonSelected = await this.checkReason();
      if (!reasonSelected) return;

      selected.forEach(s => {
        if (s.price_table.sale.localRate) {
          s.price_table.sale.localRatePrice = this.$utils.cnc(s.price_table.sale.basePrice * (100 + +s.price_table.sale.localRate) / 100);
        }
        if (!s.price_table.sale.operateRate && s.price_table.sale.operateAdjustAmount) {
          s.price_table.sale.operateRate = this.$utils.rnc(s.price_table.sale.operateAdjustAmount /  s.price_table.sale.localPrice * 100, 2) || 0;
        }
        if (!s.price_table.sale.partnerRate && s.price_table.sale.partnerAdjustAmount) {
          s.price_table.sale.partnerRate = this.$utils.rnc(s.price_table.sale.partnerAdjustAmount / s.price_table.sale.localPrice * 100, 2) || 0;
        }
        if (s.price_table.supply.localRate) {
          s.price_table.supply.localRatePrice = this.$utils.cnc(s.price_table.supply.basePrice * (100 + +s.price_table.supply.localRate) / 100);
        }
        if (!s.price_table.supply.operateRate && s.price_table.supply.operateAdjustAmount != null) {
          s.price_table.supply.operateRate = Math.round(s.price_table.supply.operateAdjustAmount /  s.price_table.supply.localPrice * 100, 2) || 0;
        }
        if (!s.price_table.supply.partnerRate && s.price_table.supply.partnerAdjustAmount != null) {
          s.price_table.supply.partnerRate = Math.round(s.price_table.supply.partnerAdjustAmount / s.price_table.supply.localPrice * 100, 2) || 0
        }
        s.price_table.pgPrice =  s.price_table.finalPriceRoundUp *  s.price_table.pgRate / 100;
        s.price_table.noRebatePrice =  s.price_table.sale.finalPriceRoundUp - s.price_table.pgPrice;
        s.price_table.supply.finalSettlePrice =  s.price_table.supply.standardPrice - s.price_table.rebate;
        s.price_table.finalMargin = s.price_table.noRebatePrice - s.price_table.supply.finalSettlePrice;
        if (s.price_table.priceRebateRule) {
          s.priceRebateTitle = `${s.price_table.priceRebateRule.page.no}. ${s.price_table.priceRebateRule.page.name}`;
        }
        if (s.price_table.priceLimitRule) {
          s.priceLimitDate = `${s.price_table.priceLimitRule.page.st} ~ ${s.price_table.priceLimitRule.page.ed}`;
        }


        if (s.price_table.partnerGrade) {
          const {discountBasePartner, discountBaseBalaan, discountCondPartner, discountCondBalaan, discountOnlyBalaan} = s.price_table.partnerGrade;
          s.price_table.discountBasePartner = discountBasePartner || 0;
          s.price_table.discountBaseBalaan = discountBaseBalaan || 0;
          s.price_table.discountCondPartner = discountCondPartner || 0;
          s.price_table.discountCondBalaan = discountCondBalaan || 0;
          s.price_table.discountOnlyBalaan = discountOnlyBalaan || 0;
        } else if (s.dp_mode && s.price_table.dynamicPricing) {
          const {discountBasePartner, discountBaseBalaan, discountCondPartner, discountCondBalaan, discountOnlyBalaan} = s.price_table.dynamicPricing;
          s.price_table.discountBasePartner = discountBasePartner;
          s.price_table.discountBaseBalaan = discountBaseBalaan;
          s.price_table.discountCondPartner = discountCondPartner;
          s.price_table.discountCondBalaan = discountCondBalaan;
          s.price_table.discountOnlyBalaan = discountOnlyBalaan;
        }
        s.orderType = s.orderType === 'purchase' ? '매입' : '수수료';
      })

      const headerFieldMap = {
        'oid': 'OID',
        'order_date': '주문일자',
        'payment_date': '결제일자',
        'goodsno': '발란코드',
        'shop_id': 'ShopID',
        'boutique': '파트너',
        'info.pg': 'PG',
        'settlekind_name': '결제수단',
        'order_status': '주문상태',
        'qty': '판매수량',
        'price_table.mappedPrice': '원본가',
        'margin_table.standardPriceSale': '판매기준가',
        'margin_table.partnerGrade.discountBasePartner': '1차 상시할인(파트너 분담)',
        'margin_table.partnerGrade.discountBaseBalaan': '1차 상시할인(발란 분담)',
        'margin_table.partnerGrade.discountCondPartner': '2차 비상시할인(파트너 분담)',
        'margin_table.partnerGrade.discountCondBalaan': '2차 비상시할인(발란 분담)',
        'margin_table.partnerGrade.discountOnlyBalaan': '3차 발란 단독 할인',
        'margin_table.partnerGrade.partnerAddAmount': '상품별 정액 할인 (파트너 분담)',
        'margin_table.partnerGrade.balaanAddAmount': '상품별 정액 할인 (발란 분담)',
        'price_table.finalPriceRoundUp': '발란회원가',
        'margin_table.mall.coupon': '쇼핑백 쿠폰 할인',
        'margin_table.mall.memberPrice': '최대혜택가',
        'price_table.rebate': '카테고리 수수료',
        'claim_cost': '고객 클레임 비용',
        'partner_claim_cost': '파트너 클레임 비용',
        'margin_table.onedayCost': '당일 배송 수수료',
        'margin_table.finalSettlePrice': '정산 금액',
        'margin_table.goodsSettlePrice': '위탁 정산금액 합계',
        'margin_table.goodsSettlePriceSeller': '위탁 판매자 정산금액',
        'margin_table.rebateConsign': '위탁 대행사 정산금액',
        'price_table.finalMargin': '마진',
        'emoney_divided': '적립금사용액',
        'supply_pg_removed_qty': 'PG제외 원가',
        'margin_table.finalTakeRate': 'take rate',
        'price_table.priceLimitRule.page.no': '가격 고정 번호',
        'price_table.priceLimitRule.page.name': '가격 고정 사유',
        'priceLimitDate': '가격 고정 기간',
        'price_table.priceLimitRule.priceLimit': '가격 고정가',
        'priceRebateTitle': '병행가격 기획전 제목',
        'dpPositiveBalaanRate': '주문 당시 MAX 할인율',
        'orderType': '매입계산서',
      };
      const header = [];
      const fields = [];
      Object.entries(headerFieldMap).forEach(([f, h]) => {
        header.push(h);
        fields.push(f);
      });

      down(selected, header, fields, `주문_PG_${this.$utils.dt()}`, 'xlsx');
      this.sendPrivateLog('downPG', {}, '다운로드', selected.length);
    },
    async downDP() {
      const selected = JSON.parse(JSON.stringify(this.items.list.filter(e => e.selected)));
      if (!selected.length) return alert('다운받을 주문을 선택해주세요');

      const reasonSelected = await this.checkReason();
      if (!reasonSelected) return;

      selected.forEach(s => {
        const pt = s.price_table;

        const dynamicObj = pt.partnerGrade || pt.dynamicPricing || {};
        const {
          discountBase,
          discountBaseBalaan,
          discountCond,
          condRatio,
          discountCondBalaan,
          discountOnlyBalaan,
          ratioByBrank,
          discountBasePartner,
          discountCondPartner
        } = dynamicObj;

        if (discountBase) {
          s.ratioByBrankPer = ratioByBrank * 100 + ' %';
          s.discountAmount = discountBase + discountCond + discountOnlyBalaan;
          s.discountAmountBalaan = discountBaseBalaan + discountCondBalaan + discountOnlyBalaan;
          s.salesProfit = pt.rebate - discountBaseBalaan - discountCondBalaan - discountOnlyBalaan;
          s.takeRate = Math.round(s.salesProfit / pt.sale.localPrice * 10000) / 100 + ' %';
          s.price_table.discountBasePartner = discountBasePartner;
          s.price_table.discountBaseBalaan = discountBaseBalaan;
          s.price_table.condRatio = (condRatio == null ? 50 : condRatio) + ' %';
          s.price_table.discountCondPartner = discountCondPartner;
          s.price_table.discountCondBalaan = discountCondBalaan;
          s.price_table.discountOnlyBalaan = discountOnlyBalaan;
        }
      });


      const headerFieldMap = {
        'oid': 'OrderID',
        'order_dt': '주문일시',
        'payment_dt': '결제일시',
        'shop_id': 'SHOP ID',
        'boutique': 'SHOP Name',
        'goodsno': '발란코드',
        'qty': '주문수량',
        'sales_price': '발란몰판매가',
        'price_table.finalPriceRoundUp': '최종판매가',
        'price_table.dynamicPricing.localPrice': '세전현지가',
        'price_table.dynamicPricing.mode': 'DP Mode',
        'b_rank': 'B Rank',
        'price_table.dynamicPricing.baseRate': '1차 상시할인률',
        'ratioByBrankPer': '1차 상시할인 발란 분담률',
        'price_table.discountBaseBalaan': '1차 상시할인 발란 분담액',
        'price_table.discountBasePartner': '1차 상시할인 파트너 분담액',
        'price_table.condRate': '2차 비상시할인률',
        'price_table.condRatio': '2차 비상시할인 발란 분담률',
        'price_table.discountCondBalaan': '2차 비상시할인 발란 분담액',
        'price_table.discountCondPartner': '2차 비상시할인 파트너 분담액',
        'price_table.balaanRate': '3차 단독할인률',
        'price_table.discountOnlyBalaan': '3차 발란단독할인 부담액',
        'price_table.adjustPrice': '조정가격',
        'discountAmount': '총 할인액',
        'discountAmountBalaan': '발란 할인액',
        'price_table.dynamicPricing.discountRate': '총 할인율',
        'price_table.rebate': '수수료',
        'price_table.rebate_rate': '수수료율',
        'salesProfit': '매출총이익',
        'takeRate': 'Takerate (%)',
        // 'sales_price': '결제대상금액',
        'dpPositiveBalaanRate': '주문 당시 MAX 할인율'
      };
      const header = [];
      const fields = [];
      Object.entries(headerFieldMap).forEach(([f, h]) => {
        header.push(h);
        fields.push(f);
      });

      down(selected, header, fields, `주문_DP_${this.$utils.dt()}`, 'xlsx');
      this.sendPrivateLog('downDP', {}, '다운로드', selected.length);
    },
    async downGlobalMall() {
      const selected = this.items.list.filter(e => e.selected);
      if (!selected.length) {
        return alert('다운받을 주문을 선택해주세요');
      }

      const reasonSelected = await this.checkReason();
      if (!reasonSelected) return;

      const headerFieldMap = {
        orderno: '쇼핑몰 주문코드(PK)',
        order_detailno: '일련번호(PK)',
        'mall_info.order_number': '채널주문코드',
        mall: '쇼핑몰',
        channel: '채널',
        order_status: '주문상세상태',
        order_receive_date: '주문접수일',
        order_receive_dt: '주문접수시각',
        receiver: '수령자',
        domestic_del_company: '택배사',
        domestic_invoice_no: '운송장번호',
        goodsnm: '상품명',
        mall_goodsno:  '쇼핑몰 상품코드',
        goodsno: '자체상품코드',
        size: '선택사항',
        qty: '수량',
        sales_price: '발란몰 판매금액',
        brand: '브랜드',
        sku: 'SKU',
        boutique: '구매처(파트너)',
        shop_id: 'ShopID',
        shop_type: 'ShopType',
        boutique_goodsno: '파트너 상품코드',
        claim_memo: '클레임사유',
        claim_cost: '고객클레임비용',
        partner_claim_cost: '파트너클레임정산',
        user_claim_memo: '고객클레임메모',
        del_prepare_date: '배송준비일',
        withdraw_date: '품절일',
        del_domestic_date: '국내배송일',
        global_inbound_date: '글로벌입고일',
        del_global_date: '글로벌배송일',
        del_complete_date: '배송완료일',
        del_plan_day: '배송기준일',
        del_taken_day: '배송소요(일)',
        del_delay_day: '배송지연(일)',
        cancel_request_date: '취소요청일',
        cancel_approve_date: '취소승인일',
        cancel_complete_date: '취소확인일',
        refund_request_date: '반품요청일',
        retrieve_request_date: '회수접수일',
        retrieve_complete_date: '회수완료일',
        refund_approve_date: '반품승인일',
        refund_complete_date: '반품완료일',
        global_refund_date: '글로벌반품일',
        change_request_date: '교환요청일',
        change_complete_date: '교환완료일',
        delivery_type: '배송유형',
        logistics: '직배송여부',
        global_del_company: '글로벌택배사',
        global_invoice_no: '글로벌운송장',
        return_del_company: '반품택배사',
        return_invoice_no: '반품운송장',
        return_shipping_del_company: '반품출고택배사',
        return_shipping_invoice_no: '반품출고운송장',
        return_memo: '반품메모',
        'info.pg': 'PG',
        'settlekind_name': '결제수단',
        'price_table.currency': '통화',
        'price_table.consumer.finalPrice': '소비자가',
        'price_table.mappedPrice': '원본가',
        'price_table.finalPriceRoundUp': '발란회원가',
        'margin_table.mall.orderSettlePrice': '실결제금액',
        'margin_table.finalSettlePrice': '정산 금액',
        exchange_rate: '적용환율',
        'abroad_del_company': '해외택배사',
        'abroad_invoice_no': '해외운송장',
        'abroad_direct': '해외직배송',
        'del_abroad_date': '해외배송일',
        'del_abroad_complete_date': '해외배송완료일',
        'del_abroad_received_date': '물류입고일',
        'refund_partner_date': '파트너반품일',
        'refund_partner_ipgo_date': '파트너반품(입고)일',
        'boutique_ordno': '부티크주문번호',
        'raw_price': '부티크발주액',
      };
      const header = [];
      const fields = [];
      Object.entries(headerFieldMap).forEach(([f, h]) => {
        header.push(h);
        fields.push(f);
      });

      const list = selected.map(({ abroad_direct, ...rest }) => ({ ...rest, abroad_direct: abroad_direct ? 'y' : 'n' }));

      down(list, header, fields, `GlobalBoard_${this.$utils.dt()}`, 'xlsx');
      this.sendPrivateLog('downGlobalMall', {}, '다운로드', selected.length);
    },
    async copyOrders(type) {
      let selected = this.items.list.filter(e => e.selected);
      if (!selected.length) return alert('카피할 주문을 선택해주세요');

      if (type === 'oid') {
        let res = this.$utils.copyToClipboard(selected.map(e => e.oid).join('\n'));
        if (res) this.$alertTop(`${selected.length} 개 복사되었습니다`);
        return;
      }

      const reasonSelected = await this.checkReason();
      if (!reasonSelected) return;

      const separator = type === 'tsv' ? '\t' : ',';
      const escape = s => (s + '').includes(separator) ? `"${(s + '').replace(/"/g, '""')}"` : s;
      let keys = 'orderno,order_detailno,mall,channel,orderer,orderer_phone,order_status,shop_id,goodsno,size,qty,sales_price,boutique,order_dt,order_receive_dt,del_prepare_dt,del_domestic_dt'.split(',');
      let cols = this.$C.DELIVER_BOARD_COLUMNS.filter(e => ~keys.indexOf(e.code));
      cols.sort((a, b) => keys.indexOf(a.code) - keys.indexOf(b.code));

      let lines = [];
      lines.push(cols.map(e => e.name).join(separator)); // header
      for (let item of selected) {
        lines.push(cols.map(e => escape(item[e.code])).join(separator)); // data
      }

      let res = this.$utils.copyToClipboard(lines.join('\n'));
      if (res) this.$alertTop(`${selected.length} 개 복사되었습니다`);

      this.sendPrivateLog('copyOrders', {type}, '복사', selected.length);
    },
    // async downCustomOld(type) {
    //   let id = {MD: 'mdXlsx', base: 'baseXlsx', OP: 'opXlsx', withdraw: 'withdrawXlsx'}[this.customName];
    //   this.busy[id] = true;
    //   this.$refs.ifr.onload = function () {
    //     this.busy[id] = false;
    //     this.$refs.ifr.onload = null;
    //   };
    //   this.$refs.ifr.src = getHost() + `/order/${id}?filename=DeliverBoard_${this.$utils.kstDT().replace(/[ :]/g, '_')}&type=${type}&` + qs.stringify(this.formCustom);
    // },
    async downCustom(type) {
      const _this = this;
      let id = {MD: 'mdXlsx', base: 'baseXlsx', OP: 'opXlsx', withdraw: 'withdrawXlsx', wrongInvoice: 'wrongInvoiceXlsx'}[this.customName];
      this.busy[id] = true;

      const reasonSelected = await this.checkReason();
      if (!reasonSelected) {
        this.busy[id] = false;
        return;
      }

      const reason = this.formDownReason.reason === 'etc' ? this.formDownReason.reasonEtc : this.formDownReason.reason;
      this.formDownReason.reason = 'work';
      this.formDownReason.reasonEtc = '';

      if (id === 'wrongInvoiceXlsx') {
        const keyMap = {
          'shop_id': '샵 ID',
          'boutique': '삽 이름',
          'orderno': '주문 번호',
          'order_detailno': '주문상세번호',
          'order_receive_dt': '주문 접수일',
          'del_prepare_dt': '배송 준비일',
          'delivery_type': '배송 유형',
          'abroad_del_company': '해외 배송사',
          'abroad_invoice_no': '해외 운송장',
          'abroad_invoice_dt': '해외 운송장 등록일',
          'abroad_wrong_invoice': '해외 가송장 의심',
          'domestic_del_company': '국내 배송사',
          'domestic_invoice_no': '국내 운송장',
          'domestic_invoice_dt': '국내 운송장 등록일',
          'domestic_wrong_invoice': '국내 가송장 의심',
          'is_member': '회원 여부',
        }

        this.$refs.json_wrong_invoice.value = JSON.stringify({
          id,
          name: `DeliverBoardFakeInvoice_${this.$utils.kstDT().replace(/[ :]/g,'_')}.${type}`,
          form: this.formCustom,
          reason,
          keyMap
        });
        this.$refs.ifr.onload = function () { console.log(id); _this.busy[id] = false; _this.$refs.ifr.onload = null; };
        this.$refs.wrong_invoice_form.submit();
        // this.busy.wrongInvoiceXlsx = false
      } else {
        this.$refs.json_data.value = JSON.stringify({
          id,
          name: `DeliverBoard_${id}_${this.$utils.kstDT().replace(/[ :]/g, '_')}.${type}`,
          form: this.formCustom,
          reason,
        });
        this.$refs.ifr.onload = function () { console.log(id); _this.busy[id] = false; _this.$refs.ifr.onload = null; };
        this.$refs.xlsx_form.submit();
      }
      // this.$refs.ifr.src = getHost() + `/order/${id}?filename=DeliverBoard_${this.$utils.kstDT().replace(/[ :]/g,'_')}&type=${type}&` + qs.stringify(this.formCustom);
    },
    setDays(n, type) {
      this.form.selectedDate = {
        start: moment().subtract(n, type),
        end: moment()
      };
    },
    showModal(row) {
      this.$refs.orderModal.showModal(row);
    },
    _showModal(oid) {
      const item = this.items.list.filter(e => e.oid === oid)[0];
      this.showModal({item});
    },
    setFormAbroadComp(comp) {
      this.form.abroad_del_company = comp;
      this.$forceUpdate();
    },
    setFormDomesticComp(comp) {
      this.form.domestic_del_company = comp;
      this.$forceUpdate();
    },

    async btnAction(row, event) {
      if (event === 'show_modal') {
        this.showModal(row);
      }
    },
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    },
    async handleFile(event) {
      let file = (event.dataTransfer || event.target).files[0];
      let typeEl = event.target;
      while (!typeEl.dataset.type && typeEl !== document.body) {
        typeEl = typeEl.parentElement;
      }
      if (!file || !file.name.endsWith('xlsx') && !file.name.endsWith('xls')) return this.$utils.alert('xlsx 파일을 업로드해주세요');
      let {headers, rows} = await readXlsx(file);
      this.uploadXlsxData(event.target, headers, rows);
    },
    async uploadXlsxData(target, headers, rows) {
      // 업로드 전 내용을 체크한다.
      // 1. 컬럼들이 올바른가
      // 2. 컬럼들 데이터 형식이 올바른가
      // 3. 상태변경이 올바른가 (서버)

      let colNameMap = {};
      this.$C.DELIVER_BOARD_COLUMNS.forEach(e=>{
        colNameMap[e.name] = e;
      });
      let unknown = headers.filter(e=>!colNameMap[e]);
      if (unknown.length) return alert('알 수 없는 컬럼들이 있습니다:\n'+unknown.join('\n'));
      // let notAllow = headers.filter(e=>!colNameMap[e].upload);
      // if (notAllow.length) return this.$utils.alert('업로드 할 수 없는 컬럼들이 있습니다:\n'+notAllow.join('\n'));
      let required = this.$C.DELIVER_BOARD_COLUMNS.filter(e=>e.required).map(e=>e.name).filter(e=>!~headers.indexOf(e));
      if (required.length) return alert('필수 컬럼이 빠져있습니다:\n'+required.join('\n'));

      let wrongRows = [];
      rows.forEach((e, i)=>{
        let wrongCols = [];
        headers.forEach(h=>{
          let tester = colNameMap[h].test;
          if (e[h] != null && e[h] !== '' && colNameMap[h].upload && tester && !tester.test(e[h])) {
            wrongCols.push(`${h}: ${e[h]}`);
          }
          if ((e[h] == null || e[h] === '') && colNameMap[h].required) {
            wrongCols.push(`${h}: (비어있음)`);
          }
        });
        if (wrongCols.length) wrongRows.push({idx:i, cols:wrongCols});
      });
      if (wrongRows.length) return alert('다음 컬럼들의 값이 올바르지 않습니다:\n'+wrongRows.map(e=>`${e.idx+2} 번째줄 ${e.cols.map(e=>e).join(', ')}`).join('\n'));
      // this.$utils.alert('파일에 문제가 없습니다');

      // 컬럼 정의에 type 이 있는 경우 해당 타입으로 casting 한다.
      let typeHeaders = headers.filter(h=>colNameMap[h].type);
      rows.forEach(row=>{
        typeHeaders.forEach(h=>{
          let type = colNameMap[h].type;
          if (type === 'number') {
            row[h] = +row[h];
          } else if (type === 'string') {
            row[h] = row[h] == null ? null : '' + row[h];
          }
        });
      });

      let j = await this.$api.postJson('/order/uploadBulk', {type:target.dataset.type, items:rows});
      if (j.ok === 1) {
        this.$utils.alert(`${j.cnt}건 업로드 되었습니다.${j.issueOrders && j.issueOrders.length ? `\n${j.issueOrders.map(e=>`${e.oid}: ${e.msg}`).join('\n')}` : ''}`);
        this.list();
      } else if (j.ok === -1) {
        this.modal.ok = true;
        this.okModalTitle = '업로드 에러 확인';
        this.okModalHtml = '<pre>' + `<h4>${j.msg}</h4>` + j.errors.map(e=>`<span class="badge badge-light">${e.orderno}:${e.order_detailno}</span> - ${e.error||''}`).join('<br/>') + '</pre>';
      }
      target.value = "";
    },
    async checkReason() {
      this.modal.download = true;
      return new Promise(resolve => {
        this.formDownReason.resolve = b => {
          if (b && this.formDownReason.reason === 'etc' && this.formDownReason.reasonEtc.trim() === '') {
            return alert('사유를 입력해주세요.');
          }
          resolve(b);
          this.modal.download = false;
        }
      });
    },
    async sendPrivateLog(func, form, type, count) {
      const baseUrl = '/order@';
      const reason = this.formDownReason.reason === 'etc' ? this.formDownReason.reasonEtc : this.formDownReason.reason;
      await this.$api.postJson('/order/privateLog', {func: baseUrl + func, form, type, count, reason});
      this.formDownReason.reason = 'order';
      this.formDownReason.reasonEtc = '';
    }
  }
}
</script>
